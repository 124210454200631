$(document).ready(function () {
    /*$("#scrollController").on('click', function (e) {
        e.preventDefault();
        var viewportHeight = $(window).height();

        $('html, body').animate({
            scrollTop: (Math.floor($(window).scrollTop() / viewportHeight)+1) * viewportHeight,
            complete: function () {
                $("#scrollController").hide();
            }
        }, 500);
        $("#scrollController").hide();
    });*/

    // Click to Scroll DOWN Functions
    $('#scrollController').click(function (event) {
        scrollDown();
        event.preventDefault();
    });

    $('#scrollToTop').click(function (event) {
        scrollToTop();
        event.preventDefault();
    });

    function scrollToTop() {
        var vheight = $(window).height();
        $('html, body').animate({
            scrollTop: 0
        }, 500);
    };

    function scrollUp() {
        var vheight = $(window).height();
        $('html, body').animate({
            scrollTop: (Math.ceil($(window).scrollTop() / vheight) - 1) * vheight
        }, 500);
    };

    // The scroll-down function
    function scrollDown() {
        var vheight = $(window).height();
        $('html, body').animate({
            scrollTop: (Math.floor($(window).scrollTop() / vheight) + 1) * vheight
        }, 500);
    };

    $(window).on('scroll', function () {
        if (($(window).scrollTop() + $(window).height()) == $(document).height()) {
            $('#scrollController').css('visibility', 'hidden').stop(true).animate({
                opacity: 0
            }, 250);
        } else {
            $('#scrollController').css('visibility', 'visible').stop(true).animate({
                opacity: 1
            }, 250);
            if ($(window).scrollTop() >= 50) {
                $('#scrollToTop').css('visibility', 'visible').stop(true).animate({
                    opacity: 1
                }, 250);
            } else {
                $('#scrollToTop').css('visibility', 'hidden').stop(true).animate({
                    opacity: 0
                }, 250);
            }
        }
    });
});