//
//  Module: languageInvoker - handles activation of the main navigational menu in mobile,
//  the desktop part is handled in css.
//
//  Exports: {function}()
//
//  2015 - Sam Pettersson
//

module.exports = function () {

    var eventHandlers = {};

    eventHandlers.click__closeLanguageInvoker = function (event) {
        var $target = $(event.target);

        if ($target.parents(".languageSwitcher").length) {
            return;
        }

        $(".languageInvoker .-activated").click();
    };

    // Handles opening and closing of the menu
    eventHandlers.click__languageInvoker = function (event) {
        event.preventDefault();

        var $this = $(this);
        var $languageSwitcher = $(".languageSwitcher");
        var $body = $("body");
        var activatedClass = "-activated";

       // if (!($(".cookie-banner").hasClass("-activated"))) {

            if (!$languageSwitcher.hasClass(activatedClass)) {
                $languageSwitcher.addClass(activatedClass);
                $this.addClass(activatedClass);
                $body.addClass("-languageActivated");

                // Don't attach eventHandler directly, wait for a repaint
                window.requestAnimationFrame(function () {
                    $("body").on("click", eventHandlers.click__closeLanguageInvoker);
                });

            } else {
                $languageSwitcher.removeClass(activatedClass);
                $this.removeClass(activatedClass);
                $body.removeClass("-languageActivated");
                $("body").off("click", eventHandlers.click__closeLanguageInvoker);
            }

            $(".languageSwitcher__container img").each(function () {
                var fileSrc = $(this).attr("data-file-src");
                var src = $(this).attr("src");

                if (fileSrc && !src) {
                    $(this).attr("src", fileSrc);
                }
            });

            $(".languageSwitcher a.languageFlag").on("click", function () {
                var selectedRegion = $(this).attr("data-currentregion");
                var region = $(this).attr("data-currentlocale");
                var consent = require("../utils/cookie").get("cookie");
                if (consent) {
                    require("../utils/cookie").set("userlocale", selectedRegion, 1000);
                    require("../utils/cookie").set("region", region, 1000);
                }
                window.location.href = $(this).attr("data-uri");
            });
        /*} else {
            $(".cookie-banner").addClass("-activated")
        }*/

    };

    eventHandlers.click__closeLanguage = function (event) {
        event.preventDefault();
        $(".languageInvoker").click();
    };

    $(".languageInvoker").on("click", eventHandlers.click__languageInvoker);
    $(".-closeLanguage").on("click", eventHandlers.click__languageInvoker);
    //$(".-closeLanguage").on("click", eventHandlers.click__languageInvoker);

    var consent = require("../utils/cookie").get("cookie");
    if (consent) {
        var hasCookieMarket = require("../utils/cookie").get("userlocale");

        if (!hasCookieMarket) {
            $(".languageInvoker").click();
        }
    }
};


