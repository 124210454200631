module.exports = {
    is: function () {

    },
    not: function () {

        $(".imageBlock .primary").once("click", function () {

            var src = $(this).find("img").attr("src");

            if (!src) {
                src = $(this).css("background-image").replace('url(', "").replace(")", "").replace(/"/g, "").replace(/'/g, "");
            }

            require('modal')({
                title: "",
                custom: $("<img/>", {
                    src: src
                }),
                class: '-image'
            });

        });

        $(".imageBlock .thumbnails").once("click", function () {

            var image = $(this).attr("data-image");

            if (image) {

                if ($(".request").attr("data-template") == "referencePage") {

                    $(this)
                        .parents(".imageBlock")
                        .find(".primary")
                        .empty()
                        .css("background-image", "url('" + image + "')");

                } else {

                    $(this)
                        .parents(".imageBlock")
                        .find(".primary")
                        .empty()
                        .append("<img />")
                        .find("img")
                        .attr("src", image);

                }

                $(this).parents(".imageBlock").removeClass("-youtube");

            }

            var youtube = $(this).attr("data-youtube");

            if (youtube) {

                $(this)
                    .parents(".imageBlock")
                    .addClass("-youtube")
                    .find(".primary")
                    .empty()
                    .append("<iframe />")
                    .find("iframe")
                    .attr("src", "https://www.youtube.com/embed/" + youtube)
                    .attr("frameborder", 0)
                    .attr("allowfullscreen", 1);

            }

        });

        $('.owl-carousel').owlCarousel({
          stagePadding: 0,
          loop: false,
          margin: 20,
          autoWidth: true,
          nav:true,
          navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>']
        });

        var switchImage = function (thumbnail) {

          var image = thumbnail.attr("data-image");

          if (image) {

            thumbnail
              .parents(".imageBlock")
              .find(".primary")
              .empty()
              .css("background-image", "url('" + image + "')");

            thumbnail.parents(".imageBlock").removeClass("-youtube");

          }

          var youtube = thumbnail.attr("data-youtube");

          if (youtube) {

            thumbnail
              .parents(".imageBlock")
              .addClass("-youtube")
              .find(".primary")
              .empty()
              .append("<iframe />")
              .find("iframe")
              .attr("src", "https://www.youtube.com/embed/" + youtube)
              .attr("frameborder", 0)
              .attr("allowfullscreen", 1);

          }

        };

        $(".thumbnail").once("click", function () {

          switchImage($(this));

        });

    }
};
