'use strict';

var eventListingPage = function() {

  var $eventListingPage;
  var $form;

  var pageIsActive = function() {

    $eventListingPage = $('.eventListingPage');
    $form = $eventListingPage.find('form');

    var $query = $form.find('input[name="query"]');
    var $country = $form.find('input[name="country"]');

    var $dropdown = $eventListingPage.find('.dropdown');
    var $dropdownFoldoutItems = $dropdown.find('.foldout');

    // Trigger form on dropdown change
    $dropdown.on('dropdown-change', function() {
      var $activeValue = $dropdown.find('.-activated').data('value');
      $country.val($activeValue);
      $form.trigger('submit');
    });

    // Form submit listener
    $form.on('submit', function(event) {

      event.preventDefault();

      var params = {
        country: $country.val(),
        query: $query.val()
      };

      // Trigger page reload
      $('body').trigger('navigate', { url: location.pathname+'?'+$.param(params) });

    });

  };

  var pageIsInactive = function() {

    // Remove event listener
    if($form) {
      $form.off('submit');
    }

  };

  return {
    is: pageIsActive,
    not: pageIsInactive
  }

};

module.exports = eventListingPage();
