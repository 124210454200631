module.exports = {
    is: function () {

    },
    not: function () {/*
        var cookieUtil = require("../../utils/cookie");
        var userlocale = cookieUtil.get("userlocale");
        var fbPage = "engcon";
        if (userlocale) {
            if (userlocale == "sv_SE")
                fbPage = "engconsweden";
            else if (userlocale == "no_NO")
                fbPage = "engconnorge";
            else if (userlocale == "da_DK")
                fbPage = "engcondenmark";
            else if (userlocale == "fi_FI")
                fbPage = "engconfi";
            else if (userlocale == "en_GB")
                fbPage = "engconuk";
            else if (userlocale == "de_DE")
                fbPage = "engcongermany";
            else if (userlocale == "fr_FR")
                fbPage = "engconfrance";
            else if (userlocale == "nl_NL")
                fbPage = "engconnetherlands";
        }
        if (userlocale == "no_NO")
            userlocale = "nn_NO";

        $(document).ready(function () {
            $.ajaxSetup({ cache: true });
            $.getScript('//connect.facebook.net/'+userlocale+'/sdk.js', function () {
                FB.init({
                    appId: fbPage,
                    version: 'v2.9'
                });
                $('#loginbutton,#feedbutton').removeAttr('disabled');
                var page_like = function (url, html_element) {
                    console.log("page_like");
                    window.setTimeout(function () {

                        window.requestAnimationFrame(function () {
                            $(".facebook-banner").removeClass("-activated");
                        });

                    }, 800);
                    cookieUtil.set("facebook", "true", 1000);
                }
                FB.Event.subscribe('edge.create', page_like);
            });
        });
        var cookieUtil = require("../../utils/cookie");

        //var userlocale = cookieUtil.get("userlocale");

        if (!($(".languageSwitcher").hasClass("-activated")) && userlocale) {

            if ($(window).width() > 520) {
                $(".fb-page").attr("data-width", "300");
                $(".fb-page").attr("data-show-facepile", "true");
                $(".fb-page").attr("data-small-header", "false");
            } else {
                $(".fb-page").attr("data-width", "725");
                $(".fb-page").attr("data-show-facepile", "false");
                $(".fb-page").attr("data-small-header", "true");
            }

            if (!cookieUtil.get("facebook")) {

                var $cookieBanner = $(".facebook-banner");

                window.setTimeout(function () {

                    window.requestAnimationFrame(function () {
                        $(".facebook-banner").addClass("-activated");
                    });

                }, 800);

                $(".facebook-banner > #close").on("click", function () {

                    $(".facebook-banner").removeClass("-activated");

                    //cookieUtil.set("facebook", "true", 1000);

                    $("body").off("click");
                });

            }

        }
    */}
};