//
//  Module: menuInvoker - handles activation of the main navigational menu in mobile,
//  the desktop part is handled in css.
//
//  Exports: {function}()
//
//  2015 - Sam Pettersson
//

module.exports = function () {

    var eventHandlers = {};

    // Upon clicking the pageContainer also click menuInvoker
    eventHandlers.click__pageContainer = function (event) {

        event.preventDefault();
        $(".menuInvoker").click();

    };

    var removeMenuActivatedTimeout;

    // Handles opening and closing of the menu
    eventHandlers.click__menuInvoker = function (event) {

        event.preventDefault();

        var $this = $(this);
        var $menu = $(".menu");
        var $body = $("body");
        var $html__$body = $("body, html");
        var $pageContainer = $(".pageContainer");
        var activatedClass = "-menuActivated";
        var activatedInvokerClass = "-activated";

        clearTimeout(removeMenuActivatedTimeout);

        if (!$pageContainer.hasClass(activatedClass)) {
            $pageContainer.addClass(activatedClass);
            $pageContainer.addClass(activatedClass + "Fixed");
            $this.addClass(activatedInvokerClass);
            $menu.addClass(activatedInvokerClass);

            $body.addClass(activatedClass);

            $html__$body.velocity("scroll", { offset: "0px", mobileHA: false });

            // Don't attach eventHandler directly, wait for a repaint
            window.requestAnimationFrame(function () {
                $pageContainer.on("click", eventHandlers.click__pageContainer);
            });

        } else {
            $pageContainer.removeClass(activatedClass);
            $this.removeClass(activatedInvokerClass);

            $body.removeClass(activatedClass);

            removeMenuActivatedTimeout = setTimeout(function () {
                $menu.removeClass(activatedInvokerClass);
                $pageContainer.removeClass(activatedClass + "Fixed");
            }, 500);

            $pageContainer.off("click", eventHandlers.click__pageContainer);
        }

    };

    $(".menuInvoker").on("click", eventHandlers.click__menuInvoker);

};