module.exports = {
    is: function () {

    },
    not: function () {

        var events = require("../../utils/events");
        var cookieUtil = require("../../utils/cookie");

        window.bookmarks = [];

        var bookmarks = window.bookmarks;

        require("pageHandlers/favorites/share")(bookmarks);

        $(".clearBookmarks").once("click", function () {

            require('modal')({
                title: "translation.favorites.clearModal.title",
                message: "translation.favorites.clearModal.body",
                button: {
                    URL: "#",
                    title: "translation.favorites.clearModal.buttonOk",
                    click: function (args, event, close) {

                        event.preventDefault();

                        cookieUtil.set("bookmarks", "");

                        bookmarks.forEach(function (bookmark) {
                            $(".product[data-id='" + bookmark + "']").find(".bookmark").removeClass("bookmarked");
                        });

                        bookmarks.splice(0, bookmarks.length);

                        close();

                    }
                },
                cancelButton: {
                    URL: "#",
                    title: "translation.favorites.clearModal.buttonCancel",
                    click: function (args, event, close) {

                        event.preventDefault();
                        close();

                    }
                }
            });

        });

        $(".contentContainer").once("click", ".bookmark", function (event) {

            event.stopPropagation();
            event.preventDefault();

            var $this = $(this);

            var productId = $(this).attr("data-id");

            if (!productId) {
              productId = $this.parents(".product").attr("data-id");
            }

            if (!$this.hasClass("-bookmarked")) {
                $this.addClass("-bookmarked");
                $this.find("i").addClass("-animate");
                bookmarks.push(productId + "--" + $(".request").attr("data-locale"));
                events.publish('favorites/add');
            } else {
                $this.removeClass("-bookmarked");
                $this.find("i").removeClass("-animate");

                var index = bookmarks.indexOf(productId + "--" + $(".request").attr("data-locale"));
                bookmarks.splice(index, 1);
                events.publish('favorites/remove');
            }

            cookieUtil.set('bookmarks', JSON.stringify(bookmarks));

        });

        var favoritesListTimeout;

        var updateFavoritesList = function () {

            clearTimeout(favoritesListTimeout);

            var fetch = function (response) {

                var allProducts = [];

                var keys = Object.keys(response);

                keys.forEach(function (key) {

                    allProducts = allProducts.concat(response[key]);

                });

                var $favoritesContainer = $(".myFavorites .favorites");

                $favoritesContainer.empty();

                var productIds = [];

                allProducts.forEach(function (product) {

                    productIds.push(product.id);

                    var favoriteItem = $("<li/>", {
                        'data-id': product.id
                    });

                    var favoriteItemContainer = $("<span/>", {
                        'class': 'container'
                    });

                    favoriteItem.append(favoriteItemContainer);

                    var removeButton = $("<a/>", {
                        'href': '#',
                        'class': 'remove',
                        'html': '<i class="fa fa-times"></i>',
                        'click': function (event) {

                            event.preventDefault();

                            var product = $(this).parents("li").attr("data-id");

                            var bookmarkButton = $(".product[data-id='" + product + "']").find(".bookmark");

                            if (bookmarkButton.length) {
                                bookmarkButton.click();
                            } else {
                                var index = bookmarks.indexOf(product);
                                bookmarks.splice(index, 1);
                                cookieUtil.set('bookmarks', JSON.stringify(bookmarks));
                            }

                        }
                    });

                    favoriteItemContainer.append(removeButton);

                    var image = $("<span/>", {
                        'class': 'image'
                    });

                    var imageContainer = $("<span/>", {
                        'class': 'container'
                    });

                    image.append(imageContainer);

                    var imageImg = $("<img/>", {
                        'src': product.images[0]
                    });

                    imageContainer.append(imageImg);

                    favoriteItemContainer.append(image);

                    var title = $("<span/>", {
                        'text': product.name + " - " + product.category,
                        'class': 'name'
                    });

                    favoriteItemContainer.append(title);

                    var footer = $("<span/>", {
                        'class': 'product__footer'
                    });

                    var footerContainer = $("<span/>", {
                        'class': 'container'
                    });

                    footer.append(footerContainer);

                    var divider = $("<div/>", {
                        'class': 'divider'
                    });

                    footerContainer.append(divider);

                    var moreInfo = $("<a>", {
                        'href': product.url,
                        'class': 'moreInfo',
                        'rv-text': "translation.moreInfo"
                    });

                    rivets.bind(moreInfo, { translation: window.translation });

                    footerContainer.append(moreInfo);
                    favoriteItemContainer.append(footer);

                    $favoritesContainer.append(favoriteItem);

                });

            };

            var update = function () {

                var bookmarkIds = window.bookmarks.join(",");
                bookmarkIds = bookmarkIds.replace(new RegExp("--" + $(".request").attr("data-locale"), 'g'), "");

                $.get("/other/api.product_searcher.json?pageId=" + bookmarkIds + "&locale=" + $(".request").attr("data-locale"))
                .done(fetch);

            };

            favoritesListTimeout = setTimeout(update, 25);

        };

        rivets.binders.myfavoritesmessage = function (el, value) {

            var $favoritesContainer = $(".myFavorites .favorites");

            if (value !== 0) {

                $(".myFavorites__container").addClass("-activated");
                $(el).removeClass("-activated");
                updateFavoritesList();

            } else {

                var $request = $(".request");
                var template = $request.attr("data-template");

                if (template !== "ourProducts") {
                    $(".myFavorites__container").removeClass("-activated");
                }

                $(el).addClass("-activated");
                $favoritesContainer.empty();

            }

        };

        rivets.binders.flyoverbookmark = function(el, value) {

            if (value === 0) {
                $(el).removeClass("-activated");
            } else {
                $(el).addClass("-activated");
            }

        };

        $(".flyover .star").once("click", function () {

            var $pageContainer = $(".pageContainer");

            var scrollTop = $pageContainer.scrollTop();
            var offsetTop = $(".myFavorites__container").offset().top;

            // set duration according to the offset
            var duration = ((offsetTop - scrollTop) / 500) * 100;

            $pageContainer.velocity("scroll", { duration: 250, offset: offsetTop });

        });

        var $myFavoritesContainer = $(".myFavorites__container");

        rivets.bind($myFavoritesContainer, { bookmarks: bookmarks });

        var cookieBookmarks = cookieUtil.get('bookmarks');

        if (cookieBookmarks) {

            var cookieBookmarksParsed = JSON.parse(cookieBookmarks);

            window.requestAnimationFrame(function () {

                cookieBookmarksParsed.forEach(function (bookmark) {
                    $(".product[data-id='" + bookmark.replace("--" + $(".request").attr("data-locale"), "") + "']").find(".bookmark").addClass("-bookmarked");
                    $(".bookmark[data-id='" + bookmark.replace("--" + $(".request").attr("data-locale"), "") + "']").addClass("-bookmarked");
                    bookmarks.push(bookmark);
                });

            });

        }


        $(".share .print").once("click", function () {

          window.open($(this).attr("data-link") + "?locale=" + $(".request").attr("data-locale") + "&pageId=" + encodeURIComponent(bookmarks.join(",")),'_blank');
          events.publish('favorites/print');

        });

    }
};
