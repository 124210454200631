module.exports = (function() {
  $(document).once("click", ".campaignPage__hero.-videoPointer", function() {
    var html = $(this).parents(".campaignPage").find(".video").html();
    html = html.replace("shouldAutoplay", "autoplay");

    require("modal")({
      title: "",
      custom: html,
      class: "-video"
    });
  });
})();
