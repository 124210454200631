module.exports = {
  is: function() {

    if (typeof google === 'undefined') {

      $.ajax({
        url: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDx1VYD9ehL4sMl9fxGryqZSegxlFUgkoM",
        dataType: "script",
        success: module.exports.is
      });

      return;

    }

    var markers = [];

    var map;

    var styles = [
      {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "saturation": 36
          },
          {
            "color": "#000000"
          },
          {
            "lightness": 40
          }
        ]
      },
      {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "visibility": "on"
          },
          {
            "color": "#000000"
          },
          {
            "lightness": 16
          }
        ]
      },
      {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 20
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 17
          },
          {
            "weight": 1.2
          }
        ]
      },
      {
        "featureType": "administrative.country",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "visibility": "on"
          },
          {
            "color": "#efefef"
          }
        ]
      },
      {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 20
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 21
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 17
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 29
          },
          {
            "weight": 0.2
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 18
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 16
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#000000"
          },
          {
            "lightness": 19
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#b5b5b5"
          },
          {
            "lightness": 17
          }
        ]
      }
    ];

    function initMap() {
      var styledMap = new google.maps.StyledMapType(styles,
        {name: "Styled Map"});

      map = new google.maps.Map(document.getElementById('map'), {
        center: {lat: 48.6908333333, lng: 9.14055555556},
        zoom: 2,
        scrollwheel: false,
        disableDefaultUI: false,
        zoomControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT
        },
        streetViewControl: false
      });

      map.mapTypes.set('map_style', styledMap);
      map.setMapTypeId('map_style');
    }

    initMap();

    var cookieUtil = require("../../utils/cookie");

    function showPosition(position) {

      cookieUtil.set("position", JSON.stringify({
        coords: {
          longitude: position.coords.longitude,
          latitude: position.coords.latitude
        }
      }));

      var center = new google.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude
        );

      map.panTo(center);
      map.setZoom(8);

    }

    var setBounds = function () {

      var bounds = new google.maps.LatLngBounds();

      for (var i = 0; i < markers.length; i++) {
        bounds.extend(markers[i].getPosition());
      }

      map.fitBounds(bounds);

      map.setZoom(4);

    };

    var cookiePostition = cookieUtil.get("position");

    if (cookiePostition) {

        showPosition(JSON.parse(cookiePostition));

    } else if (navigator.geolocation) {

      navigator.geolocation.getCurrentPosition(showPosition, function () {

        setBounds();

      });

    } else {

      setBounds();

    }

    var setMapOnMarkers = function (_map) {
      markers.forEach(function (marker) {
        marker.setMap(_map);
      });
    };

    var updateMarkers = function () {

      setMapOnMarkers(null);

      $(".flexboxGrid .partner").each(function () {

        var infowindow = new google.maps.InfoWindow({
          content: "<div class='partner -googleWindow'>" + $(this).html() + "</div>"
        });

        var image = '/webdav/files/resources/img/marker.png';

        var pos = new google.maps.LatLng(
          $(this).attr("data-lat"),
          $(this).attr("data-lng")
        );

        var marker = new google.maps.Marker({
          position: pos,
          map: map,
          icon: image
        });

        marker.addListener('click', function() {
          infowindow.open(map, marker);
        });

        markers.push(marker);

      });

    };

    $(".dealersPage .alphabetFilter li").once("click", function (event) {

        event.preventDefault();

        var $this = $(this);

        $("body, html").velocity("scroll", { duration: 250, offset: $(".flexboxGrid .partner[data-start-letter='" + $this.text() + "']").first().offset().top });

    });

    var fetch = function (response) {

      var $flexboxGrid = $(".dealersPage .results .flexboxGrid");

      $flexboxGrid.empty();

      var callback = function () {

        $(".dealersPage .alphabetFilter li").each(function () {

          var $this = $(this);

          if ($(".flexboxGrid .partner[data-start-letter='" + $this.text() + "']").length) {
            $this.addClass("-active");
          } else {
            $this.removeClass("-active");
          }

        });

        updateMarkers();

      };

      response.forEach(function (asp, index) {

        asp.directionsTranslation = $(".results").attr("data-directions-translation");

        require("template")("partner", asp, function (html) {
          $flexboxGrid.append(html);

          if ((index + 1) === response.length) {
            callback();
          }
        });

      });

    };

    var $dropdownCountry = $(".dropdown--country");
    var $dropdownRegion = $(".dropdown--region");
    var $filterSearch = $(".dealersPage .filterSearch");

    var updateFilter = function () {

      var afSearcherBase = "/other/api.af_searcher.json?locale=" + $(".request").attr("data-locale") + "&";

      var dropdownCountryActivatedValue = $dropdownCountry.find(".-activated").attr("data-value");

      if (dropdownCountryActivatedValue) {
        afSearcherBase += "country=" + dropdownCountryActivatedValue + "&";
      }

      var dropdownRegionActivatedValue = $dropdownRegion.find(".-activated").attr("data-value");

      if (dropdownRegionActivatedValue) {
        afSearcherBase += "region=" + dropdownRegionActivatedValue + "&";
      }

      var query = $filterSearch.find("input").val();

      if (query) {
        afSearcherBase += "query=" + query + "&";
      }

      $.get(afSearcherBase).done(fetch);

    };

    // init markers
    updateMarkers();

    $dropdownCountry.once("dropdown-change", function () {
      updateFilter();

      if ($dropdownCountry.find(".-activated").attr("data-value")) {
        $dropdownRegion.removeClass('-disabled');
      } else {
        $dropdownRegion.addClass('-disabled');
      }

    });

    $dropdownRegion.once("dropdown-change", function () {
      updateFilter();
    });

    $filterSearch.find("a").once("click", function () {
      updateFilter();
    });

    $filterSearch.find("input").once("keyup", function (event) {

      if (event.keyCode === 13) {
        updateFilter();
      }

    });

  },
  not: function() {}
};
