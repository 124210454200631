module.exports = {
    is: function () {

    },
    not: function () {

        var cookieUtil = require("../../utils/cookie");

        window.segments = {};

        var segments = window.segments;

        window.excavator = {};

        var excavator = window.excavator;

        $(".flyover .excavator").once("click", function () {

            require("pageHandlers/ourProducts/configuratorOptions").modal(excavator, cookieUtil);

        });

        rivets.binders.flyoverexcavator = function (el, value) {

            if (typeof value === "undefined") {
                $(el).removeClass("-activated");
            } else {
                $(el).addClass("-activated");
            }

        };

        rivets.bind($('.flyover'), { bookmarks: window.bookmarks, excavator: window.excavator });

        var cookieExcavator = cookieUtil.get('excavator');

        if (cookieExcavator) {

            var cookieExcavatorParsed = JSON.parse(cookieExcavator);
            var keys = Object.keys(cookieExcavatorParsed);

            keys.forEach(function (key) {
                excavator[key] = cookieExcavatorParsed[key];
            });

        }

    }
};