module.exports = {
    is: function () {

        var cookieUtil = require("../../utils/cookie");

        rivets.binders.showcompatible = function (el, value) {

            if (typeof value === "undefined") {
                $(el).removeClass("-activated");
            } else {
                $(el).addClass("-activated");
            }

        };

        rivets.binders.cardimage = function (el, value) {
            $(el).css("background-image", "url('" + value[0] + "')");
        };

        rivets.bind($(".productPage .compatible"), { excavator: window.excavator });

        $(".productFAQ .foldable").once("click", function () {

            var $this = $(this);

            if (!$this.hasClass("-activated")) {
                $this.addClass("-activated");
                $this.find(".indicator").find("i").removeClass("fa-plus").addClass("fa-minus");
            } else {
                $this.removeClass("-activated");
                $this.find(".indicator").find("i").addClass("fa-plus").removeClass("fa-minus");
            }

        });

        var getHistoryLinks = function () {

            var id = $(".productPage").attr("data-id");
            var currentIndex = 0;

            window.productsList.forEach(function (item, index) {

                if (item.id === id) {
                    currentIndex = index;
                }

            });

            var back = currentIndex - 1;
            var next = currentIndex + 1;

            if (back < 0) {
                back = undefined;
            }

            if (next > (window.productsList.length - 1)) {
                next = undefined;
            }

            var productSearcherBase = "/other/api.product_searcher.json?pageId=";

            if (typeof back !== "undefined") {
                var backProduct = window.productsList[back];
                productSearcherBase = productSearcherBase + backProduct.id + ",";
            }

            if (typeof next !== "undefined") {
                var nextProduct = window.productsList[next];
                productSearcherBase = productSearcherBase + nextProduct.id + ",";
            }
            
            $.get("/other/api.product_searcher.json?locale=" + $(".request").attr("data-locale")).done(function (response) {
                var s = JSON.stringify(response);
                var p = JSON.parse(s);
                
                var categoryKeys = Object.keys(p);

                var products = [];

                categoryKeys.forEach(function (categoryKey) {

                    p[categoryKey].forEach(function (product) {
                        products.push(product);
                    });

                });
                
                products.forEach(function (product) {

                    product.new = product.new == "Ja" ? "Ja" : "";
                    
                    if (backProduct) {
                        if (product.id === backProduct.id) {
                            $(".historyLinks .-prev").addClass("-activated");
                            $(".historyLinks .-prev a").attr("href", product.url);

                            var prevTimeout;

                            $(".historyLinks .-prev a, .historyLinks .-prev .card").on({
                                mouseenter: function () {
                                    $(".historyLinks .-prev").addClass("-cardActivated");
                                    clearTimeout(prevTimeout);
                                },
                                mouseleave: function () {
                                    prevTimeout = setTimeout(function () {
                                        $(".historyLinks .-prev").removeClass("-cardActivated");
                                    }, 250);
                                }
                            });

                            rivets.bind($(".historyLinks .-prev"), { product: product });
                        }
                    }

                    if (nextProduct) {
                        if (product.id === nextProduct.id) {
                            $(".historyLinks .-next").addClass("-activated");
                            $(".historyLinks .-next a").attr("href", product.url);

                            var nextTimeout;

                            $(".historyLinks .-next a, .historyLinks .-next .card").on({
                                mouseenter: function () {
                                    $(".historyLinks .-next").addClass("-cardActivated");
                                    clearTimeout(nextTimeout);
                                },
                                mouseleave: function () {
                                    nextTimeout = setTimeout(function () {
                                        $(".historyLinks .-next").removeClass("-cardActivated");
                                    }, 250);
                                }
                            });

                            rivets.bind($(".historyLinks .-next"), { product: product });
                        }
                    }

                });

                if (backProduct || nextProduct) {

                    $(".historyLinks").addClass("-activated");

                }

            });

        };

        if (window.productsList) {
            getHistoryLinks();
        }

    },
    not: function () {
        
    }
};
