'use strict';

var $nav;
var $navItems;
var $activeItem;
var $segments;
var $select;
var $videoLink;

var toggleSegment = function(e, el) {
  e.preventDefault();
  e.stopPropagation();

  var $el = el ? $(el) : $(this);
  var $segment = el ? $($el.data('value')) : $($el.attr('href'));

  $navItems.add($select.find('.dropdown__container li')).removeClass('-active -activated');
  $segments.removeClass('-active');

  $el.add('[data-value="'+$el.attr('href')+'"]')
    .add('[href="'+$el.data('value')+'"]')
    .addClass('-active -activated');

  $segment.addClass('-active');

};

var videoModal = function(e) {
  e.preventDefault();
  e.stopPropagation();

  require('modal')({
    title: '',
    custom: '<div class="close-icon"><i class="fa fa-times"></i></div><iframe src="'+this.href+'" frameborder="0" allowfullscreen></iframe>',
    class: '-segmentsPage -video'
  });

};

$('<div class="quantity-nav"><div class="quantity-button quantity-up">+</div><div class="quantity-button quantity-down">-</div></div>').insertAfter('.quantity input');
$('.quantity').each(function () {
    var spinner = jQuery(this),
      input = spinner.find('input[type="number"]'),
      btnUp = spinner.find('.quantity-up'),
      btnDown = spinner.find('.quantity-down'),
      min = input.attr('min'),
      max = input.attr('max');

    btnUp.click(function () {
        var oldValue = parseFloat(input.val());
        if (oldValue >= max) {
            var newVal = oldValue;
        } else {
            var newVal = oldValue + 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
    });

    btnDown.click(function () {
        var oldValue = parseFloat(input.val());
        if (oldValue <= min) {
            var newVal = oldValue;
        } else {
            var newVal = oldValue - 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
    });

});

var setCurrency = function (element) {
    $("#currency").val(element.data("value"));
}

module.exports = {
    is: function () {

    var step1 = $('div.segmentsPage__section.step1');
    var step2 = $('div.segmentsPage__section.step2');
    var step3 = $('div.segmentsPage__section.step3');
    var step4 = $('div.segmentsPage__section.step4');
    var step5 = $('div.segmentsPage__section.step5');

    var cookieUtil = require("../../utils/cookie");
    var events = require("../../utils/events");

    require("pageHandlers/segments/slider")();

    var updateFilter = require("pageHandlers/segments/updateFilter");

    var $list = $(".dropdown.dropdown--currency").find(".foldout");
    $list.on("click", "li", function () {
        if ($(this).data("value")) {
            setCurrency($(this));
            step3.addClass("show");
            updateFilter();
        }
    });

    var $rev = $("#hourlyRevenue");
    $rev.on("change", function (event) {
        step4.addClass("show");
        updateFilter();
    });

    $nav = $('.segmentsPage__nav');
    $navItems = $nav.find('a');
    $segments = $('.segmentsPage__segment');
    $select = $('.segmentsPage__select');
    $videoLink = $('table a[href*="youtube"]');

    $navItems.on('click', toggleSegment);
    $select.on('dropdown-change', toggleSegment);
    $videoLink.on('click', videoModal);

    if ($(location).attr('hash')) {
        $('a[href=' + $(location).attr('hash') + ']').click();
    }

    $('div#workTypeSelection input').click(function () {
        $("#worktype").val($(this).val());
        step2.addClass("show");
        updateFilter();
    });

    $('div.tabNav a').click(function () {
        var tabIndex = $(this).parent().parent().prevAll().length + 1;
        var $parentElement = $("#workTypeSelection");

        $parentElement.slideUp('fast', function () {
            $parentElement.children('.current').removeClass('current');
            $parentElement.slideDown('normal', function () {
                $(this).find('[data-tabindex=' + tabIndex + ']').addClass('current');
                $(this).find('[data-tabindex=' + tabIndex + ']').first().children(":first").prop("checked", true);
                $("#worktype").val($(this).find('[data-tabindex=' + tabIndex + ']').first().children(":first").val());
                updateFilter();
            });
        });
    });
  },
  not: function() {

    if ($navItems) {
      $navItems.off('click', toggleSegment);
      $select.off('dropdown-change', toggleSegment);
      $videoLink.off('click', videoModal);
    }

  }
};
