'use strict';

var getParameterByName = function(name, url) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  var results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

var tags = {
  init: function() {
    this.bindEvents();
  },
  bindEvents: function() {
    var elements = '.js-tag-search';
    $('html').on('click', elements, this.handler);
  },
  handler: function(e) {
    e.preventDefault();
    e.stopPropagation();

    var $this = $(this);
    var tagQuery = $this.text().trim();
    var searchQuery = getParameterByName('q') ? '&q=' + getParameterByName('q') : '';
    var searchPage = $('.request').data().searchpage;

    $('html').trigger('navigate', {
      url: searchPage + '?tag=' + tagQuery + searchQuery
    });

  }
};

module.exports = tags.init();
