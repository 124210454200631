module.exports = {
    is: function () {

        var $dropdownReferenceCountry = $(".dropdown--referenceCountry");
        var $dropdownReferenceCategory = $(".dropdown--referenceCategory");

        var referencesList = window.referencesList = [];

        var updateFilter = function () {

            var selectedCountry = $dropdownReferenceCountry
                .find(".dropdown__container li.-activated")
                .attr("data-country");

            var selectedCategory = $dropdownReferenceCategory
                .find(".dropdown__container li.-activated")
                .attr("data-category");

            var query = $(".filterSearch input").val();

            var $referenceBlock = $(".referenceBlock");
            $(".referenceBlock li a").each(function (index) {
                var reference = {
                    link: $(this).attr("href"),
                    id: $(this).data("id")
                }
                referencesList.push(reference);
            });
            var getCallback = function (response) {/*
                console.log(response);
                var tags = [];

                response.find(".reference__footer ul .tag").each(function (index) {
                    tags.push($(this).text());
                });

                response.find(".referenceBlock").each(function (index) {
                    var reference = {
                        link: $(this).find("a").attr("href"),
                        id: $(this).find("a").data("id")
                    }
                    referencesList.push(reference);
                });*/
                /*
                $referenceBlock.empty();

                referencesList.splice(0, referencesList.length);

                referencesList.forEach(function (reference) {

                    referencesList.push(reference);

                    var referenceItem = $("<li>"
                        + "<a href='" + reference.link + "'>"
                        + "<div class='image' style='background-image: url(" + reference.listImage + ")'>"
                        + "<div class='description'>"
                        + "<h4>"
                        + reference.headline
                        + "</h4>"
                        + "</div>"
                        + "</div>"
                        + "<div class='reference__footer'>"
                        + "<p>" + reference.person +"</p>"
                        + "<ul>"
                        + "</ul>"
                        + "</div>"
                        +"</a>"
                        + "</li>"
                    );

                    if (reference.flag) {
                        referenceItem.find(".reference__footer").append("<img class='flag' src='/webdav/files/resources/img/flags/" + reference.flag + "'/>");
                    }

                    reference.tags.forEach(function (tag) {
                        referenceItem.find(".reference__footer ul").append("<li class='tag'>" + tag.name + "</li>");
                    });

                    $referenceBlock.append(referenceItem);

                });*/

                if (referencesList.length == 0) {
                  $(".noResults").removeClass("-deactivated");
                } else {
                  $(".noResults").addClass("-deactivated");
                }

            };

            var baseUri = window.location.pathname + "?";

            if (selectedCountry) {
                baseUri = baseUri + "country=" + selectedCountry + "&";
            }

            if (selectedCategory) {
                baseUri = baseUri + "category=" + selectedCategory + "&";
            }

            if (query) {
                baseUri = baseUri + "query=" + query + "&";
            }

            baseUri = baseUri + "locale=" + $(".request").attr("data-locale") + "&";

            $.get(baseUri).done(getCallback);

        };
        
        updateFilter();
        console.log(referencesList);
        // when dropdown changes, reload data from API
        $dropdownReferenceCountry.once("dropdown-change", function () {
            updateFilter();
        });

        $dropdownReferenceCategory.once("dropdown-change", function () {
            updateFilter();
        });

        $(".reference .filterSearch a").once("click", function () {
            updateFilter();
        });

        $(".reference .filterSearch input").once("keyup", function (event) {

            if (event.keyCode == 13) {
                updateFilter();
            }

        });

    },
    not: function () {

    }
};
