module.exports = function () {

    var cookieUtil = require("../../../utils/cookie");

    var slider = $(".filter__slider")[0];

    var totalSavings;
    var userlocale;

    var currency = $("#currency").val();
    var workHours = parseFloat($(".min i.val").text());
    var hourlyRevenue = parseFloat($("#hourlyRevenue").val());
    var worktype = parseFloat($("#worktype").val());
    
    segments.workHours = workHours;
    segments.currency = currency;
    segments.worktype = worktype;
    segments.hourlyRevenue = hourlyRevenue;

    cookieUtil.set('segments', JSON.stringify(segments))
    
    if (slider) {
      var values = slider.noUiSlider.get();
      window.sliderVal = values;
    }

    if (cookieUtil.get('userlocale')) {
        userlocale = cookieUtil.get('userlocale').replace("_", "-");
    } else
        userlocale = "en-GB";

    if (currency !== "") {

        if (workHours && hourlyRevenue && worktype) {
            totalSavings = worktype * hourlyRevenue * workHours;
            totalSavings = Math.round(totalSavings);
            $(".estimatedSavings").text(new Intl.NumberFormat(userlocale, { style: 'currency', currency: currency }).format(totalSavings));
        } else
            $(".estimatedSavings").text("");
    }
};