module.exports = {
    is: function () {

        $(".staff .foldable").once("click", function () {

            var $this = $(this);

            if (!$this.hasClass("-activated")) {
                $this.addClass("-activated");
                $this.find(".indicator").find("i").removeClass("fa-plus").addClass("fa-minus");
            } else {
                $this.removeClass("-activated");
                $this.find(".indicator").find("i").addClass("fa-plus").removeClass("fa-minus");
            }

        });

        var $dropdownContactTitle = $(".dropdown--contactTitle");
        var $dropdownContactCountry = $(".dropdown--contactCountry");

        var filterChanged = function () {

            var base = "/other/api.contact_searcher.json?locale=" + $(".request").attr("data-locale") + "&getManagementDepartment=false";

            var department = $dropdownContactTitle.find("li.-activated").attr("data-value");

            if (department && department.length) {
              base += "&workDepartment=" + encodeURIComponent(department);
            }

            var query = $(".contact__filter .filterSearch input").val();

            if (query.length) {
              base += "&query=" + query;
            }

            var country = $dropdownContactCountry.find("li.-activated").attr("data-value");

            if (country && country.length) {
              base += "&country=" + country;
            }

            $.get(base).done(function (res) {

                var $staff = $(".staff");

                $staff.empty();

                if (res.length === 0) {
                  $(".noResults").removeClass("-deactivated");
                } else {
                  $(".noResults").addClass("-deactivated");
                }

                res.forEach(function (item) {

                    if (item.faq) {

                      $staff.append("<div class='faq'><h2>" + $(".staff").attr("data-faq-title") + " " + item.name + "</h2></div>");

                      item.faq.forEach(function (faq) {

                          var faqHolder = $staff.find(".faq:last-child");

                          require("template")("faq", faq, function (html) {
                            faqHolder.append(html);
                          });

                      });

                    }

                    $staff.append("<h2 data-section-name='" + item.name + "'>" + item.name + "</h2>");
                    $staff.append("<div class='cards'></div>");

                    var cards = $staff.find(".cards:last-child");

                    item.items.forEach(function (contact) {

                      cards.append('<div class="staff__card" data-name="$item.name" data-title="$item.name">' +
                      '<a href="' + contact.link + '">' +
                        '<div class="staff__photo">' +
                        '<img src="' + contact.image + '">' +
                        '</div>' +
                        '</a><div class="staff__info"><a href="' + contact.link + '">' +
                        '<h4 class="staff__name">' +
                        contact.name +
                      '</h4>' +
                      '<p class="staff__title"></p>' +
                      '<p class="staff__station"></p>' +
                      '</a>' +
                      '</div>' +
                      '</div>');

                      if (contact.workTitle) {
                        cards.find(".staff__card:last-child .staff__title").text(contact.workTitle.join(', '));
                      }

                      if (contact.stationering) {
                        //cards.find(".staff__card:last-child .staff__station").text(contact.stationering.join(', '));
                      }
                      
                      if (contact.email && contact.showEmail) {

                        cards.find(".staff__card:last-child .staff__info a").last().append(
                          '<a href="mailto:' + contact.email + '" class="staff__email">'
                          + contact.email
                          + '</a>'
                        );

                      }

                      if (contact.phone) {

                        cards.find(".staff__card:last-child .staff__info a").last().append(
                          '<a href="tel:'+ contact.phone +'" class="staff__phone">'
                          + contact.phone
                          + '</a>'
                        );

                      }

                      if (contact.mobile) {

                        cards.find(".staff__card:last-child .staff__info a").last().append(
                          '<a href="tel:'+ contact.mobile +'" class="staff__phone">'
                          + contact.mobile
                          + '</a>'
                        );

                      }

                    });

                });

            });

        };

        $dropdownContactTitle.once("dropdown-change", function () {
          filterChanged();
        });

        $dropdownContactCountry.once("dropdown-change", function () {
          filterChanged();
        });

        $(".contact__filter .filterSearch a").once("click", function () {
          filterChanged();
        });

        $(".contact__filter .filterSearch input").once("keyup", function (event) {

          if (event.keyCode == 13) {
            filterChanged();
          }

        });

        // trigger from start on page load
        $dropdownContactCountry.trigger("dropdown-change");

    },
    not: function () {

    }
};
