module.exports = function () {

    var facetHandler = function (resultItem) {

        var elem, elemContainer;

        if (resultItem.type === "page") {

            elem = $("<li/>");

            elemContainer = elem.append("<a href='" + resultItem.data.uri + "' class='page'></a>").find(".page");
            elemContainer.append("<h3>" + resultItem.data.displayName + "</h3>");

            if (resultItem.data.content) {
              elemContainer.append("<p class='content'>" + resultItem.data.content + "</p>");
            } else {
              elemContainer.append("<p class='content'></p>");
            }

            elemContainer.append("<p class='breadcrumb'>" + resultItem.data.breadcrumb + "</p>");

            return elem;

        } else if (resultItem.type === "product") {

            elem = $("<li/>");

            elemContainer = elem.append("<a href='" + resultItem.data.uri + "' class='product'></a>").find(".product");

            elemContainer.append("<div class='image'><img src='" + resultItem.data.images[0] + "' /></div>");

            elemContainer.append("<div class='content'></div>");
            elemContainer.find(".content").append("<h3>" + resultItem.data.name + " - " + resultItem.data.category + "</h3>");
            elemContainer.find(".content").append("<p>" + resultItem.data.shortDescription + "</p>");

            return elem;

        } else if (resultItem.type === "contact") {

          elem = $("<li/>");

          elemContainer = elem.append("<a href='" + resultItem.data.uri + "' class='contact'></a>").find(".contact");

          elemContainer.append("<div class='image'><img src='" + resultItem.data.image + "' /></div>");

          elemContainer.append("<div class='content'></div>");
          elemContainer.find(".content").append("<h4>" + resultItem.data.name + "</h4>");
          elemContainer.find(".content").append("<p class='-title'>" + resultItem.data.workTitle.join(', ') + "</p>");
          elemContainer.find(".content").append("<p class='-stationing'>Stationering: " + resultItem.data.stationering.join(', ') + "</p>");

          elemContainer.find(".content").append("<div class='details'></div>");

          elemContainer.find(".details").append("<p><i class='fa fa-envelope-o'></i> " + resultItem.data.email + "</p>");

          if (resultItem.data.phone) {
            elemContainer.find(".details").append("<p><i class='fa fa-phone'></i> " + resultItem.data.phone + "</p>");
          }

          if (resultItem.data.mobile) {
            elemContainer.find(".details").append("<p><i class='fa fa-phone'></i> " + resultItem.data.mobile + "</p>");
          }

          return elem;

        }

    };

    var hideTimeout;

    var showFoldout = function () {

        clearTimeout(hideTimeout);

        var foldoutActivatedClass = "-foldoutActivated";
        var activatedClass = "-activated";
        var $foldout = $(".search-foldout");
        var $foldoutContainer = $(".search-foldout-container");

        if ($foldout.hasClass(activatedClass)) {
            return;
        }

        $foldout.css("display", "block");
        $foldoutContainer.addClass(foldoutActivatedClass);

        window.requestAnimationFrame(function () {
            $foldout.addClass(activatedClass);
        });

    };

    var hideFoldout = function () {

        var foldoutActivatedClass = "-foldoutActivated";
        var activatedClass = "-activated";
        var $foldout = $(".search-foldout");
        var $foldoutContainer = $(".search-foldout-container");

        if (!$foldout.hasClass(activatedClass)) {
            return;
        }

        $foldout.removeClass(activatedClass);
        $foldoutContainer.removeClass(foldoutActivatedClass);

        hideTimeout = setTimeout(function () {

          $foldout.css("display", "none");
          $foldoutContainer.removeClass(foldoutActivatedClass);

        }, 250)

    };

    var eventHandlers = {};

    var searchTimeout;

    eventHandlers.keyUp__search = function (event) {

        if (event.keyCode === 13) {
          $(".search .searchResultsPage").attr("href", $(".search .searchResultsPage").attr("href").split('?')[0] + "?q=" + $(".search input").val());
          $(".search .searchResultsPage").click();
          clearTimeout(searchTimeout);
          hideFoldout();
          setTimeout(function () {
            hideFoldout();
          }, 250);
          return;
        }

        if ($(window).width() < 520) {
          return;
        }

        var $target = $(event.target);
        var $this = $(this);
        var $result = $this.parents("div").find(".search-foldout").find(".result");

        var locale = $this.attr("data-search-locale");
        var query = $target.val();
        var search = $this.attr("data-search");

        if (!query) {
            hideFoldout();
            $result.empty();
            return;
        }

        var performSearch = function () {

          $.get(search + "?query=" + query + "&locale=" + locale + "&limit=6").done(function (response) {

            if (response.error) {
              hideFoldout();
              return;
            }

            var payload = response.payload;

            var facets = Object.keys(payload.result);

            if (!facets.length) {
              hideFoldout();
              return;
            } else {
              showFoldout();
            }

            $result.empty();

            facets.forEach(function (facet) {

              var cleaned = facet.replace("'", "");

              var $facet = $result.append("<div class='facet'><h2>" + facet + "</h2><ul class='flexboxGrid' data-name='" + cleaned + "'></ul></div>").find(".facet");

              payload.result[facet].forEach(function (resultItem, index) {

                var $li = $facet.find("ul[data-name='" + cleaned + "']").append(facetHandler(resultItem));

                $li.find("li:last-child").velocity({
                  opacity: 1
                }, { delay: 250 + (25 * index), duration: 250 });

              });

            });

            var outerHeight = 0;

            $result.children().each(function () {

              outerHeight = outerHeight + $(this).outerHeight();

            });

            $result.velocity({
              height: outerHeight
            }, { duration: 250 });

          });

        };

        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(performSearch, 150);

    };

    $(".header .search").on("keyup", eventHandlers.keyUp__search);

};
