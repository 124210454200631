//
//  Module: navigation - handles navigation logic, pushstate, etc
//
//  Exports: {function}()
//
//  2015 - Sam Pettersson
//

//var cookieUtil = require("../utils/cookie");
// var events = require("../utils/events");

// require("pageHandlers/ourProducts/slider")();

//var updateFilter = require("pageHandlers/ourProducts/updateFilter");

module.exports = function() {

  // collect all pageLoads in a global variable
  window.pageHistory = [];
  var pageHistory = window.pageHistory;

  // global variables for timeouts
  var onErrorTimeout;
  var activateLoaderTimeout;

  // Small plugin to only attach an eventHandler once
  $.fn.once = function(a, b, c) {
    return this.each(function() {
      if (c) {
        $(this).off(a).on(a, b, c);
      } else {
        $(this).off(a).on(a, b);
      }
    });
  };

  //
  //  Handle page inconsistencies here
  //
  //  not__[templatename] runs when the page loaded doesn't have the template of said name
  //  [templatename] runs when the page loaded have the template of said name
  //
  var pageHandlers = {};

  //
  //  add modules here, they will be ran automatically according to .request
  //
  var pageHandlersModules = [
    {
      // this is a ghost module to handle the cookie banner
      module: require("pageHandlers/cookie"),
      name: "cookie"
    },
    {
      // this is a ghost module to handle favorites in the footer
      module: require("pageHandlers/favorites"),
      name: "favorites"
    },
    {
      // this is a ghost module to handle favorites in the footer
      module: require("pageHandlers/excavator"),
      name: "excavator"
    },
    {
      // this is a ghost module to handle tabBars
      module: require("pageHandlers/tabBar"),
      name: "tabBar"
    },
    {
      // this is a ghost module to handle imageBlocks
      module: require("pageHandlers/imageBlock"),
      name: "imageBlock"
    },
    {
      module: require("pageHandlers/startpage"),
      name: "startpage"
    },
    {
      module: require("pageHandlers/ourProducts"),
      name: "ourProducts"
    },
    {
    // TODO inconsistent naming
      module: require("pageHandlers/productPage"),
      name: "productTemplate"
    },
    {
      module: require("pageHandlers/subPage"),
      name: "subPage"
    },
    {
      module: require("pageHandlers/subPage"),
      name: "subPageVertical"
    },
    {
      module: require("pageHandlers/servicePartnersPage"),
      name: "servicePartnersPage"
    },
    {
      module: require("pageHandlers/referencesPage"),
      name: "91.26000acb15438959c84891ba"
    },
    {
      module: require("pageHandlers/contactPage"),
      name: "contactPage"
    },
    {
      module: require("pageHandlers/searchResultsPage"),
      name: "searchResultsPage"
    },
    {
      module: require("pageHandlers/eventListingPage"),
      name: "eventListingPage"
    },
    {
      module: require("pageHandlers/FAQListingPage"),
      name: "FAQListingPage"
    },
    {
      module: require("pageHandlers/accordion"),
      name: "accordion"
    },
    {
      module: require("pageHandlers/conceptPage"),
      name: "conceptPage"
    },
    {
      module: require("pageHandlers/registerProductPage"),
      name: "registerProductPage"
    },
    {
      module: require("pageHandlers/referencePage"),
      name: "referencePage"
    },
    {
      module: require("pageHandlers/baumaForm"),
      name: "baumaForm"
    },
    {
      module: require("pageHandlers/segmentsPage"),
      name: "91.5b8894b416b1d8ccc57e35"
    },
    {
      module: require("pageHandlers/tkdForm"),
      name: "tkdForm"
    },
    {
      module: require("pageHandlers/dealersPage"),
      name: "dealersPage"
    },
    {
        module: require("pageHandlers/nobleCampaignPage"),
        name: "nobleCampaignPage"
    },
    {
        module: require("pageHandlers/printarchive"),
        name: "printarchive"
    },
    {
        // this is a ghost module to handle the facebook banner
        module: require("pageHandlers/facebook"),
        name: "facebook"
    },
    {
        module: require("pageHandlers/nobleCarousel"),
        name: "nobleCarousel"
    }, 
    {
        module: require("pageHandlers/metatest"),
        name: "91.5b8894b416b1d8ccc57129f"
    }, 
  ];




  // adds each module in pageHandlersModules to pageHandlers
  for (var i = 0; i < pageHandlersModules.length; i++) {

    var module = pageHandlersModules[i].module;

    // prints the name of the module when ran
    var is = function(module, moduleName) {

      return function() {

        console.log("--- loading ---");
        console.log(moduleName);
        console.log("---------------");

        module.is();

      };

    };

    pageHandlers["not__" + pageHandlersModules[i].name] = module.not;
    if (pageHandlersModules[i].name)
        pageHandlers[pageHandlersModules[i].name] = is(module, pageHandlersModules[i].name);
    else
        pageHandlers[pageHandlersModules[i].id] = is(module, pageHandlersModules[i].id);

  }

  // runs a all instances of not__template except defined template
  var runNot = function (template) {
    for (var pageHandlerProperty in pageHandlers) {
      if (pageHandlers.hasOwnProperty(pageHandlerProperty)) {
        if (pageHandlerProperty !== ("not__" + template) && pageHandlerProperty.indexOf("not__") !== -1) {
          pageHandlers[pageHandlerProperty]();
        }
      }
    }
  };

  // initialises nots for page bootstrapping
  var initNot = function() {

    var $request = $(".request");

    var template = $request.attr("data-template");
    
    //Below addition to identify by id
    var id = $request.attr("data-templateid") ? $request.attr("data-templateid") : false;

    var pageHandler = null;

    if (id) {
        pageHandler = pageHandlers[id];
        runNot(id);
    } else {
        pageHandler = pageHandlers[template];
        runNot(template);
    }

    // get pageHandler for said template
    //var pageHandler = pageHandlers[template];

    //runNot(template);

    // run pageHandler if defined
    if (pageHandler) {
      pageHandler();
    }

  };

  // run init not
  initNot();

  // object to hold all eventHandlers
  var eventHandlers = {};

  // fallback fix for browsers not supporting pushState correctly
  eventHandlers.state__load = function() {

    var $request = $(".request");

    if ($request.attr("data-editor") === "true") {
      return;
    }

    if ($request.attr("data-template") === "error") {
      return;
    }

    var requestURI = $request.attr("data-uri");
    var url = window.location.href;

    var template = $request.attr("data-template");

    // convert URL to URI and remove get variables
    var URI = url.replace(/^.*\/\/[^\/]+/, '').split('?')[0];

    // if request page isn't same as URI
    if (requestURI !== URI && template !== 'error') {
      eventHandlers.state__performNavigation(window.location.href, true);
    }

  };

  eventHandlers.state__performNavigation = function(url, loadingAnimation) {

    // stop eventual timeout trying to reload prev error
    clearTimeout(onErrorTimeout);

    var activatedClass = "-activated";

    var deactivateLoader = function() {
      setTimeout(function () {
        $("body").removeClass("-loading");
      }, 250);
    };

    var activateLoader = function() {
      if (loadingAnimation) {
        $("body").addClass("-loading");
      }
    };

    // convert absolute url to relative and remove get variables
    var relativeUrl = url.replace(/^.*\/\/[^\/]+/, '').split('?')[0];

      var buildSidebarMenu = function () {

          var menuUrl = null;

          // adds active classes to sidebar menu items, etc
          var builder = function () {

              // get each topLevel > a
              $(".topLevel__item > a, .secondaryTopLevel__item > a").each(function () {

                  // get link
                  var $link = $(this);

                  // get topLevel__item from parents
                  var $topLevel__item = $(this).parents(".topLevel__item, .secondaryTopLevel__item");

                  var $subLevel__link;

                  $topLevel__item.find(".subLevel__item > a, .secondarySubLevel__item > a").each(function () {

                      // set $subLevel__link and class if url matches
                      if ($(this).attr("href") === menuUrl) {
                          $subLevel__link = $(this);
                          $subLevel__link.addClass(activatedClass);
                      } else {
                          $(this).removeClass(activatedClass);
                      }

                  });

                  // make sure $subLevel__link is initialized
                  if (!$subLevel__link) {
                      $subLevel__link = false;
                  }

                  // if url matches of $subLevel__link exists
                  if ($link.attr("href") === menuUrl || $subLevel__link) {

                      $link.addClass("-activated");

                      var $topLevel__item__link = $topLevel__item.find("> a");
                      $topLevel__item__link.addClass(activatedClass);

                      // remove all active subLevel__items if statement validates
                      if ($topLevel__item__link.is($link) && $subLevel__link === false) {
                          $topLevel__item.find(".subLevel__item, .secondarySubLevel__item").find("a").removeClass(activatedClass);
                      }

                      $topLevel__item.addClass(activatedClass);
                      $topLevel__item.find(".subLevel, .secondarySubLevel").addClass(activatedClass);

                  } else {

                      $link.removeClass(activatedClass);

                      // remove all active subLevel__items
                      $topLevel__item.find("a").removeClass(activatedClass);
                      $topLevel__item.removeClass(activatedClass);
                      $topLevel__item.find(".subLevel, .secondarySubLevel").removeClass(activatedClass);

                  }

              });

          };

          var startBuilder = function () {

              // get an activated menuInvoker if it exists
              var $menuInvokerActivated = $(".menuInvoker.-activated");

              // if mobile menu is invoked, wait before completing animation
              if ($menuInvokerActivated.length) {
                  $menuInvokerActivated.click();
                  setTimeout(builder, 500);
              } else {
                  builder();
              }

          };

          var $request = $(".request");

          // get all parents for current page
          var requestNavigation = JSON.parse($request.attr("data-navigation"));

          // get each and every link in the menu and reverse the array
          var $menuItemLinks = $(".topLevel__item a, .secondaryTopLevel__item a").get().reverse();

          // get corresponding parent link for the url we are trying to navigate to
          $($menuItemLinks).each(function (index) {

              // url for the current link being iterated
              var linkUrl = $(this).attr("href");

              requestNavigation.forEach(function (navigationUrl) {

                  // if we already found menuUrl stop
                  if (menuUrl) {
                      return;
                  }

                  // if url matches set menuUrl
                  if (navigationUrl === linkUrl) {
                      menuUrl = linkUrl;
                  }

              });

              // when iteration is finished
              if (index === $menuItemLinks.length - 1) {

                  // if we couldnt find any url reset to default
                  if (!menuUrl) {
                      menuUrl = relativeUrl;
                  }

                  // start building
                  startBuilder();

              }

          });

      };

      // only show loader when request > 150ms
      activateLoaderTimeout = setTimeout(activateLoader, 150);

      // get locale before loading through ajax
      var oldRequestLocale = $(".request").attr("data-locale");
      var oldRequestURI = $(".request").attr("data-uri");
      var oldRequestTemplate = $(".request").attr("data-template");

      pageHistory.push({
          'locale': oldRequestLocale,
          'uri': oldRequestURI,
          'template': oldRequestTemplate
      });

      // perform ajax request to fetch html
      $.get(url, function (html) {

          clearTimeout(activateLoaderTimeout);
          deactivateLoader();

          // hide errorMessage
          $(".errorMessage").removeClass("-activated");

          var contentContainerClass = "contentContainer";

          // create new dom object from html
          var $contentContainer = $(html);

          // find contentContainer in retrieved html
          var $contentContainerHtml = $contentContainer.find("." + contentContainerClass).html();

          // we didn't set title before, do it now
          document.title = $contentContainer.filter('title').text();

          // replace html in the contentContainer
          $("." + contentContainerClass).html($contentContainerHtml);

          // time to add activated classes to sidebar menu
          buildSidebarMenu();

          // get template name from request element
          var template = $(".request").attr("data-template");

          // run all nots except not__template
          runNot(template);

          // get pageHandler for said template
          var pageHandler = pageHandlers[template];

          // run pageHandler if defined
          if (pageHandler) {
              pageHandler();
          }

          var newRequestLocale = $(".request").attr("data-locale");

          // if the locale of the target url isn't the same as the previous force a reload
          if (newRequestLocale !== oldRequestLocale) {
              window.location.replace(url);
          }

          // send to google analytics
          if (typeof ga !== 'undefined') {
              ga('send', 'pageview', {
                  'page': relativeUrl,
                  'title': document.title
              });
          }

          // send to activecampaign
          require("activecampaign")();

          $("body").trigger("navigation-done");

      }).error(function (response) {

          // hide loader
          clearTimeout(activateLoaderTimeout);
          deactivateLoader();

          var $errorMessage = $(".errorMessage");

          // show errorMessage
          $errorMessage.addClass(activatedClass);

          // remove pageContent
          $(".contentContainer").html("");

          // run all nots
          runNot();

          // retry loading page every 3000ms
          var doErrorTimeout = function () {
              onErrorTimeout = setTimeout(function () {
                  eventHandlers.state__performNavigation(url);
              }, 3000);
          };

          // show correct errorMessage depending on status
          if (response.status === 0) {

              $errorMessage.find(".not-found").hide();
              $errorMessage.find(".error").hide();
              $errorMessage.find(".error-disconnected").show();

              // set document title to errorMessage attributed title
              document.title = $errorMessage.find(".error-disconnected").attr("data-title");

              doErrorTimeout();

          } else if (response.status === 401) {

              require("template")("loginForm", {}, function (html) {
                  $(".contentContainer").html(html);
              });

              $errorMessage.hide();

          } else if (response.status !== 404) {

              $errorMessage.find(".not-found").hide();
              $errorMessage.find(".error").show();
              $errorMessage.find(".error-disconnected").hide();

              // set document title to errorMessage attributed title
              document.title = $errorMessage.find(".error").attr("data-title");

              doErrorTimeout();

          }  else {

              var $response = $(response.responseText);

              // get request element
              $(".contentContainer").html($response.find(".request").parent("div").html());

              $errorMessage.find(".not-found").show();
              $errorMessage.find(".error").hide();
              $errorMessage.find(".error-disconnected").hide();

              // set document title to errorMessage attributed title
              document.title = $errorMessage.find(".not-found").attr("data-title");

          }

          // time to add activated classes to sidebar menu
          buildSidebarMenu();

      });

  };

  eventHandlers.state__change = function() {
      var State = History.getState();

      // send url to navigation handler
      eventHandlers.state__performNavigation(State.url, true);
  };

  eventHandlers.click__link = function (event, data) {

      event.preventDefault();

      var $link = $(this);

      if (!!$link.attr("data-ignore-navigation") === true) {
        return;
      }

      var $request = $(".request");

      // get url from $link
      var url = $link.attr("href");

      if (data) {
        url = data.url;
      }

      if (document.documentMode) {
        if (document.documentMode == 9) {
          window.location.replace(url);
          return;
        }
      }

      if ($link.is('[href^=mailto:]') || $link.is('[href^=tel:]')) {
        window.location.href = $link.attr('href');
        return;
      }

      if ($link.attr("target") === "_blank") {
          window.open(url, '_blank');
          return;
      }

      if ($request.attr("data-editor") === "true") {
          window.location.replace(url);
          return;
      }

      if (url === "#") {
          return;
      }

      if (!url) {
          return;
      }

      // if url is a request for a language change
      if (url.indexOf("?language=") !== -1) {

        var cookieUtil = require("../utils/cookie");
        var requestLanguages = $(".requestLanguages li");
        var urlForCorrespondingPage;

        requestLanguages.each(function () {

          if (url.indexOf($(this).attr("data-locale")) !== -1) {
              urlForCorrespondingPage = $(this).attr("data-url");
              if (cookieUtil.get("cookie")) {
                  cookieUtil.set('languagePreference', $(this).attr("data-locale"), 356 * 10, true);
              }
          }

        });

        if (urlForCorrespondingPage) {
          window.location.replace(urlForCorrespondingPage);
        } else {
          window.location.replace(url);
        }

        return;
      }

      if (url.indexOf(".html") === -1) {
        window.open(url, '_blank');
        return;
      }

      // regex for checking if url is relative or not
      var relativeUrlRegex = new RegExp('^(?:[a-z]+:)?//', 'i');

      // test according to regex and turn result to opposite
      var isRelative = !relativeUrlRegex.test(url);

      if (isRelative) {
          // push url into history, omit title
          History.pushState({ url: url }, "engcon", url);
      } else {
          // url isn't relative, load as href
          window.location.href = url;
      }

      $body.once("navigation-done", function () {
        $("body, html").velocity("scroll", { duration: 250, offset: 0 });
        $body.off("navigation-done");
      });

      $(".searchClose").click();

  };

  // listen for changes to state and call performNavigation
  History.Adapter.bind(window, 'statechange', eventHandlers.state__change);
  History.Adapter.onDomLoad(eventHandlers.state__load);

  // catch all link clicks on the site
  var $body = $("html");
  $body.once("click", "a", eventHandlers.click__link);
  $body.once("navigate", "a", eventHandlers.click__link);
  $body.once("navigate", eventHandlers.click__link);


  // function scrollToSection(category) {

  //     // replace
  //     String.prototype.replaceArray = function(find, replace) {
  //       var replaceString = this;
  //       var regex;
  //       for (var i = 0; i < find.length; i++) {
  //         regex = new RegExp(find[i], "g");
  //         replaceString = replaceString.replace(regex, replace[i]);
  //       }
  //       return replaceString;
  //     };


  //      var find = ['å', 'ä', 'ö', 'Å', 'Ä', 'Ö', 'é', 'ø', ' '];
  //      var replace = ['a', 'a', 'o', 'A', 'A', 'O', 'e', 'o', ''];

  //     var offsetTop = $(".category[data-name='" + category.replaceArray(find, replace) + "']").offset().top;

  //     // set duration according to the offset
  //     var duration = (offsetTop / 500) * 100;

  //     $(".pageContainer").velocity("scroll", {duration: duration, offset: offsetTop});
  // }


  // // click
  // var excavator = window.excavator;

  // if (window.location.href.indexOf("categoryNav") !== -1) {

  //     var splitted = window.location.href.split("?categoryNav=");
  //     var category = splitted[1];

  //     if (excavator.brand) {
  //       $(".categoriesBar").on("data-update", function() {

  //         splitted = window.location.href.split("?categoryNav=");
  //         category = splitted[1];
  //         scrollToSection(category);

  //       });
  //     } else {
  //       scrollToSection(category);
  //     }
  //   }


  //   // scroll

  //   $(".categoriesBar").on("click", "a", function(event) {

  //     event.preventDefault();

  //     // prevent other event listeners to do their thing
  //     event.stopPropagation();

  //     var dataName = $(this).attr("data-name");

  //     scrollToSection(dataName);

  //   });

};
