module.exports = (function () {
  var cookieUtil = require("../utils/cookie");
  var searchParameters = require("../utils/searchParameters");
  var email = cookieUtil.get('email') ? decodeURIComponent(cookieUtil.get('email')) : '';

  if (typeof searchParameters.email !== "undefined") {
    email = decodeURIComponent(searchParameters.email);
  }
  
  var consent = require("../utils/cookie").get("cookie");
  if (consent) {
      cookieUtil.set('email', email, 356 * 10, true);
  }

  // Prevent multiple script tags
  var script = document.getElementById('script');
  if (script) {
    script.parentElement.removeChild(script);
  }

  var trackcmp_email = '';
  var trackcmp = document.createElement("script");
  trackcmp.id = 'script';
  trackcmp.async = true;
  trackcmp.type = 'text/javascript';
  trackcmp.src = '//trackcmp.net/visit?actid=65796322&e='+encodeURIComponent(trackcmp_email)+'&r='+encodeURIComponent(document.referrer)+'&u='+encodeURIComponent(window.location.href);
  var trackcmp_s = document.getElementsByTagName("script");
  if (trackcmp_s.length) {
    trackcmp_s[0].parentNode.appendChild(trackcmp);
  } else {
    var trackcmp_h = document.getElementsByTagName("head");
    trackcmp_h.length && trackcmp_h[0].appendChild(trackcmp);
  }
});
