$(document).ready(function(){
    $('div.tabNav a').click(function() {
        var curChildIndex = $(this).parent().parent().prevAll().length + 1;
        $(this).parent().parent().parent().children('.current').removeClass('current');
        $(this).parent().parent().addClass('current');
        //$(this).parent().parent().parent().parent().next('.tabContainer').addClass('this');
        $(this).parent().parent().parent().parent().find('.tabContainer').children('.current').slideUp('fast', function () {
            $(this).removeClass('current');
            $(this).parent().children('div:nth-child('+curChildIndex+')').slideDown('normal',function() {
                $(this).addClass('current');
            });
        });
        return false;        
    });
});
/*
$(document).ready(function(){
    $('div.tabNav a').click(function() {
        var curChildIndex = $(this).parent().parent().parent().prevAll().length + 1;
        $(this).parent().parent().parent().parent().children('.current').removeClass('current');
        $(this).parent().parent().parent().addClass('current');
        $(this).parent().parent().parent().parent().next('.tabContainer').children('.current').slideUp('fast', function () {
            $(this).removeClass('current');
            $(this).parent().children('div:nth-child('+curChildIndex+')').slideDown('normal',function() {
                $(this).addClass('current');
            });
        });
        return false;        
    });
}); */