module.exports = function () {

    var container = $("<div/>", {
        'class': 'svg -loader'
    });

    var svg = $("<svg/>", {
        'viewbox': '0 0 256 256',
        'version': '1.1',
        'xmlns': 'http://www.w3.org/2000/svg',
        'xmlns:xlink': 'http://www.w3.org/1999/xlink',
        'xmlns:sketch': 'http://www.bohemiancoding.com/sketch/ns'
    }).attr('width', '256px').attr('height', '256px');

    var g = $("<g/>", {
        'id': 'Page-1',
        'stroke': 'none',
        'stroke-width': '1',
        'fill': 'none',
        'fill-rule': 'evenodd',
        'sketch:type': 'MSPage'
    });

    svg.append(g);

    var polygon = $("<polygon/>", {
        'class': 'outer',
        'stroke': '#000000',
        'stroke-width': '5',
        'stroke-linejoin': 'round',
        'points': '128 3 236.253175 65.5 236.253175 190.5 128 253 19.7468245 190.5 19.7468245 65.5',
        'id': 'Polygon-1'
    });

    g.append(polygon);

    var innerPolygon = $("<polygon/>", {
        'class': 'inner',
        'fill': '#534E4E',
        'stroke-linejoin': 'round',
        'points': '127.5 28 213.669528 77.75 213.669528 177.25 127.5 227 41.3304723 177.25 41.3304723 77.75',
        'id': 'Polygon-2'
    });

    g.append(innerPolygon);

    container.append(svg);

    var outerContainer = $("<div/>", {
        'class': 'outer-svg'
    });

    outerContainer.append(container);

    var outerHtml = $("<div/>");
    outerHtml.append(outerContainer);

    return outerHtml.html()

}();