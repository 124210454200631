// Plugins
require('./modernizr.js');
require('jQuery');
require('velocity');
require('history');
require('nouislider');
window.sightglass = require("sightglass");
require('rivets');
require('owlcarousel');

// Utils
window.strt = {};
window.strt.utils = require('./utils');
window.translation = {};

if (document.documentMode) {

  if (parseInt(document.documentMode, 10) <= 11) {
    document.documentElement.className += ' ieLessThan11';
  }

  document.documentElement.className += ' ie' + document.documentMode;
}

var getTranslation = function() {

  var translation = JSON.parse($(".request").attr("data-translation"));
  var translationKeys = Object.keys(translation);

  for (var i = 0; i < translationKeys.length; i++) {
    window.translation[translationKeys[i]] = translation[translationKeys[i]];
  }

};

// Wait until document is ready before running modules
$(document).ready(function() {

  //require('usersnap');
  require('analytics');
  //require('tagmanager');
  require('scrollController');
  require('facebook');
  require('translatedPages');

  //universal tab navigation
  require('tabNavigation');

  // send visit event
  require('activecampaign')();

  // default rivets formatters
  rivets.formatters.count = function(value) {

    if (typeof value === "undefined") {
      return 0;
    } else {
      return value.length;
    }

  };

  rivets.formatters.bookmarkcount = function(value) {

    if (typeof value === "undefined") {
      return 0;
    } else {

      var count = 0;

      value.forEach(function(item) {

        if (item.indexOf("--" + $(".request").attr("data-locale")) !== -1) {
          count += 1;
        }

      });

      return count;

    }

  };

  // default rivets binders
  rivets.binders.image = function(el, value) {
    $(el).attr("src", value);
  };

  var locale = $(".request").attr("data-locale");

  getTranslation();

    // Modules
  require('menuInvoker')();
  require('languageInvoker')();
  require('searchInvoker')();
  require('navigation')();
  require('search')();
  require('dropdown')();
  require('pageHandlers/updateDropdown')();
  require('newsletter');
  require('heroVideo');
  require('logging');
  require('tag');
  require('currentLocale');

  if ($(".splash").length) {

    var out = function() {

      $(".splash").addClass("-out");

    };

    $(".splash").on("click", out);

      setTimeout(out, 8000);

  }

});
