'use strict';

var utils = require('../utils');

$.reCapthaValidated = function () {
    newsletter.validate();
};

var newsletter = {
    init: function () {
        this.form = $('.js-newsletter-form');
        this.inputs = this.form.find('[name]');

        this.form.on('submit', this.send.bind(this));
        this.inputs.on('focus', function () {
            $(this).removeClass('-invalid');
        });
    },
    getData: function () {
        var data = {};

        this.inputs.toArray().forEach(function (item) {
            data[item.name] = item.value;
        });

        return data;
    },
    validate: function () {
        var result = true;
        this.inputs.toArray().forEach(function (item) {
            if ($(item).is(':checkbox')) {
                if (!$(item).is(':checked')) {
                    $(item).parent().addClass('-invalid');
                    $(item).addClass('-invalid');
                    result = false;
                } else {
                    $(item).parent().removeClass('-invalid');
                    $(item).removeClass('-invalid');
                }
            } else {
                if (item.value === '') {
                    $(item).addClass('-invalid');
                    result = false;
                }
                if (item.name === "newscountry" && item.value !== '') {
                    $(item).removeClass('-invalid');
                }
                if ((item.name === "newsstate" && item.value !== '' && $("#newscountry").val() === "US") || (item.name === "newsstate" && item.value === '' && $("#newscountry").val() !== "US")) {
                    $(item).removeClass('-invalid');
                }
            }
        });
        return result;
    },
    send: function (event) {
        event.preventDefault();

        var action = this.form.attr('action');
        var method = this.form.attr('method');
        var data = this.getData();
        var $button = this.form.find('[type="submit"]');
        var $response = $('.js-newsletter-form-response');
        var buttonWidth = $button.outerWidth();

        event.preventDefault();

        $.ajax({
            beforeSend: function () {
                $button.addClass('-loading');
                return (newsletter.validate());
            },
            complete: function () {
            },
            url: action,
            method: method,
            data: data,
            success: function (data) {
                //window.location = '/dashboard.php';
                // Set Session Php Etc 
                //alert('php ran');
                $button.removeClass('-loading');
                return true;
            }
        })
        /*
        $.ajax({
          url: action,
          method: method,
          data: data,
          beforeSend: function () {
              if (newsletter.validate()) {
                  newsletter.validate();
                  var buttonWidth = $button.outerWidth();
                  $button.css('width', buttonWidth).addClass('-loading');
                  return true;
              }
          }
        })*/
        .done(function (res) {
            if (data.email && utils.cookie.get('email') !== data.email) {
                utils.cookie.set('email', data.email, 365 * 10);
                utils.cookie.set('cookie', true);
                $(".cookie-banner").removeClass("-activated");
            }

            if (this.validate()) {
                this.form[0].reset();
            }

            var message = res.result === 'error' ? this.form.data('error') : this.form.data('success');
            $response.text(message);
            if (res.result !== 'error') {
                this.form.hide(300);
                $(".js-newsletter-form-response").addClass("-active");
                $(".subscription-form-response").removeClass("-active");
            }

        }.bind(this))
        .always(function () {
            $button.css('width', '').removeClass('-loading');
        });

    }
};

var subscription = {
    init: function () {
        this.form = $('.subscription-form');
        this.inputs = this.form.find('[name]');

        this.form.on('submit', this.send.bind(this));
        this.inputs.on('focus', function () {
            $(this).removeClass('-invalid');
        });
    },
    getData: function () {
        var data = {};

        this.inputs.toArray().forEach(function (item) {
            data[item.name] = item.value;
        });

        return data;
    },
    validate: function () {
        var result = true;
        this.inputs.toArray().forEach(function (item) {
            if ($(item).is(':checkbox')) {
                if (!$(item).is(':checked')) {
                    $(item).parent().addClass('-invalid');
                    $(item).addClass('-invalid');
                    result = false;
                } else {
                    $(item).parent().removeClass('-invalid');
                    $(item).removeClass('-invalid');
                }
            } else {
                if (item.value === '' && item.name !== "company") {
                    $(item).addClass('-invalid');
                    result = false;
                }
                if (item.name === "usercountry" && item.value !== '') {
                    $(item).removeClass('-invalid');
                }
                if ((item.name === "userstate" && item.value !== '' && $("#usercountry").val() === "US") || (item.name === "userstate" && item.value === '' && $("#usercountry").val() !== "US")) {
                    $(item).removeClass('-invalid');
                }
                /* || (item.value === '' && item.name === "userstate" && $("#usercountry").val() === "US")*/
            }
        });
        return result;
    },
    send: function (event) {
        event.preventDefault();

        var action = this.form.attr('action');
        var method = this.form.attr('method');
        var data = this.getData();
        var $button = this.form.find('[type="submit"]');
        var $response = $('.subscription-form-response');
        var buttonWidth = $button.outerWidth();

        event.preventDefault();

        $.ajax({
            beforeSend: function () {
                $button.addClass('-loading');
                return (subscription.validate());
            },
            complete: function () {
            },
            url: action,
            method: method,
            data: data,
            success: function (data) {
                //window.location = '/dashboard.php';
                // Set Session Php Etc 
                //alert('php ran');
                $button.removeClass('-loading');
                return true;
            }
        })
        /*$.ajax({
            url: action,
            method: method,
            data: data,
            beforeSend: function () {
                //if (subscription.validate()) {
                    var buttonWidth = $button.outerWidth();
                    $button.css('width', buttonWidth).addClass('-loading');
                    return (subscription.validate());
                //}
            }
        })*/
        .done(function (res) {
            if (data.email && utils.cookie.get('email') !== data.email) {
                utils.cookie.set('email', data.email, 365 * 10);
                utils.cookie.set('cookie', true);
                $(".cookie-banner").removeClass("-activated");
            }

            if (this.validate()) {
                this.form[0].reset();
            }

            var message = res.result === 'error' ? this.form.data('error') : this.form.data('success');
            $response.text(message);
            if (res.result !== 'error') {
                this.form.hide(300);
                $(".js-newsletter-form-response").removeClass("-active");
                $(".subscription-form-response").addClass("-active");
            }

        }.bind(this))
        .always(function () {
            $button.css('width', '').removeClass('-loading');
        });
    }
};

var toggle = {
    init: function () {
        this.container = $('.newsletter');
        var $newsletterButton = this.container.find('#newsletter-button');
        var $magazineButton = $('#magazine-button');
        $(".subscription-form").hide();
        $newsletterButton.addClass('-activated');

        $newsletterButton.click(function () {
            if (!$newsletterButton.hasClass('-activated')) {
                $newsletterButton.addClass('-activated');
                $(".subscription-form").hide(300);
                $(".js-newsletter-form").show(300);
                $magazineButton.removeClass('-activated');
                if ($(".js-newsletter-form-response").hasClass("-active")) {
                    $(".js-newsletter-form-response").removeClass("-active");
                } else {
                    if ($('.js-newsletter-form-response').text == $(".js-newsletter-form").data('error')) {
                        $(".js-newsletter-form-response").addClass("-active");
                    }
                    $(".subscription-form-response").removeClass("-active");
                }
            }
        });

        $magazineButton.click(function () {

            if (!$magazineButton.hasClass('-activated')) {
                $magazineButton.addClass('-activated');
                $(".js-newsletter-form").hide(300);
                $(".subscription-form").show(300);
                $newsletterButton.removeClass('-activated');
                if ($(".subscription-form-response").hasClass("-active")) {
                    $(".subscription-form-response").removeClass("-active");
                } else {
                    if ($('.subscription-form-response').text == $(".subscription-form").data('error')) {
                        $(".subscription-form-response").addClass("-active");
                    }
                    $(".js-newsletter-form-response").removeClass("-active");
                }
            }
        });

    }
};

var subdropdown = {
    country: function () {
        var $list = $(".subscription-form .dropdown.dropdown--country").find(".foldout");
        $list.on("click", "li", function (event) {
            updatesubCountry($(this));
        });
    },
    state: function () {
        var $list = $(".subscription-form .dropdown.dropdown--state").find(".foldout");
        $list.on("click", "li", function (event) {
            updatesubState($(this));
        });
    }
};

var updatesubCountry = function (element) {
    $("#usercountry").val(element.data("value"));
    if (element.data("value") == "US") {
        $(".subscription-form .dropdown.dropdown--state").removeClass("-inactive");
        $("#userstate").val($(".subscription-form .dropdown.dropdown--state").find(".dropdown__container li.-activated").data("value"));
    } else {
        $(".subscription-form .dropdown.dropdown--state").addClass("-inactive");
        $("#userstate").val("");
    }
}

var updatesubState = function (element) {
    $("#userstate").val(element.data("value"));
}

var newsdropdown = {
    country: function () {
        var $list = $(".js-newsletter-form .dropdown.dropdown--country").find(".foldout");
        $list.on("click", "li", function (event) {
            updatenewsCountry($(this));
        });
    },
    state: function () {
        var $list = $(".js-newsletter-form .dropdown.dropdown--state").find(".foldout");
        $list.on("click", "li", function (event) {
            updatenewsState($(this));
        });
    }
};

var updatenewsCountry = function (element) {
    $("#newscountry").val(element.data("value"));
    if (element.data("value") == "US") {
        $(".js-newsletter-form .dropdown.dropdown--state").removeClass("-inactive");
        $("#state").val($(".js-newsletter-form .dropdown.dropdown--state").find(".dropdown__container li.-activated").data("value"));
    } else {
        $(".js-newsletter-form .dropdown.dropdown--state").addClass("-inactive");
        $("#newsstate").val("");
    }
}

var updatenewsState = function (element) {
    $("#newsstate").val(element.data("value"));
}

module.exports = newsletter.init();
module.exports = subscription.init();
module.exports = toggle.init();
module.exports = subdropdown.country();
module.exports = subdropdown.state();
module.exports = newsdropdown.country();
module.exports = newsdropdown.state();

if ($(".subscription-form .dropdown.dropdown--country").find(".dropdown__container li.-activated").data("value") == "US")
    $(".subscription-form .dropdown.dropdown--state").removeClass("-inactive");

if ($(".js-newsletter-form .dropdown.dropdown--country").find(".dropdown__container li.-activated").data("value") == "US")
    $(".js-newsletter-form .dropdown.dropdown--state").removeClass("-inactive");