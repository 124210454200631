'use strict';

var accodrion = function() {

  var is = function() {

  };

  var not = function() {

    $("body").once('click', ".js-accordion .accordion__header", function() {
      var $this = $(this);
      var $parent = $this.parent();
      var state = $this.data('state');
      var height = $parent.find('.accordion__content').outerHeight();
      var $body = $parent.find('.accordion__body');
      var $link = $parent.find('.accordion__link');

      if(state === 'active') {
        $this.data('state', 'closed');
        $parent.removeClass('-active');
        $link.removeClass('-active');

        $body.velocity('stop')
          .velocity('reverse');

      } else {
        $this.data('state', 'active');
        $parent.addClass('-active');
        $link.addClass('-active');

        $body.velocity('stop')
          .velocity({
            height: [height, 0]
          }, {
            duration: 200,
            complete: function() {
              $body.css('height', 'auto');
            }
          });
      }

    });


  };

  return {
    is: is,
    not: not
  }

};

module.exports = accodrion();
