'use strict';

var $container;
var $video;
var $videoLink;

var videoModal = function(e) {
  e.preventDefault();
  e.stopPropagation();

  require('modal')({
    title: '',
    custom: '<div class="close-icon"><i class="fa fa-times"></i></div><iframe src="' + this.href + '" frameborder="0" allowfullscreen></iframe>',
    class: '-segmentsPage -video'
  });

};

var conceptPage = function() {

  var is = function() {

    // Container
    $container = $('.conceptPage__machine');
    $video = $container.find('video');
    $videoLink = $('a[href*="youtube"]');

    $videoLink.on('click', videoModal);
    $video[0].play();

    // Create mobile markup
    (function() {

      var $mobileContainer = $('<div/>', {
        class: 'conceptPage__mobile sv-fluid-grid sv-grid-responsive'
      });

      var texts = $container.find('.sv-column-4 > .sv-layout').children().toArray();

      $(texts[0]).clone().appendTo($mobileContainer);
      $(texts[1]).clone().appendTo($mobileContainer);
      $(texts[2]).clone().appendTo($mobileContainer);

      $('<img/>', {
        src: "/webdav/files/resources/video/concept.gif",
        alt: "Concept engcon"
      }).appendTo($mobileContainer);

      $mobileContainer.appendTo($container);

    })();

    // End if mobile
    if (require('../../utils/device').isMobile) {
      return false;
    }

  };

  var not = function() {

    if ($container) {
      $videoLink.off('click', videoModal);
    }

  };

  return {
    is: is,
    not: not
  };

};

module.exports = conceptPage();
