module.exports = {
    is: function () {

    },
    not: function () {

        var cookieUtil = require("../../utils/cookie");

        if (!cookieUtil.get("cookie")) {

            var $cookieBanner = $(".cookie-banner");
            var currentRegion = cookieUtil.get("region");
            var activatedRegions = JSON.parse($cookieBanner.attr("data-regions"));
            /*
            var showNotice = false;

            activatedRegions.forEach(function (region) {

                if (currentRegion === region) {
                    showNotice = true;
                }

            });

            if (!showNotice) {
                return;
            }*/

            window.setTimeout(function () {

                window.requestAnimationFrame(function () {
                    $(".cookie-banner").addClass("-activated");
                });

            }, 800);

            $(".cookie-banner .button-secondary.-button-agree").on("click", function () {

                $(".cookie-banner").removeClass("-activated");

                cookieUtil.set("cookie", "true", 1000);

            });

        }

    }
};