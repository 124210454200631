'use strict';

var FAQListingPage = function() {

  var $FAQListingPage;
  var $form;

  var is = function() {

    $FAQListingPage = $('.FAQListingPage');
    $form = $FAQListingPage.find('form');

    $form.on('submit', function(event) {

      event.preventDefault();

      var query = $form.find('input[name="query"]').val();

      $('body').trigger('navigate', { url: location.pathname+'?query='+query });

    });

  };

  var not = function() {

    if($form) {
      $form.off('submit');
    }

  };

  return {
    is: is,
    not: not
  }

};

module.exports = FAQListingPage();
