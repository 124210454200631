'use strict';

module.exports = (function() {

  var self = {};

  // Set Cookie
  self.set = function(name, value, time, rootDomain) {
    var expires = null;
    if (time) {
      var date = new Date();
      date.setTime(date.getTime() + (time * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }
    var cookie = name + "=" + value + expires + ";";
    var hostname = window.location.hostname;
    if (rootDomain) {
      var dots = hostname.split(".");
      if (dots.length > 1) {
        cookie += "domain=." + dots[dots.length - 2] + "." + dots[dots.length - 1] + ";";
      } else {
        cookie += "domain=." + hostname + ";";
      }
    }
    document.cookie = cookie + "path=/;";
  };

  // Get cookie
  self.get = function(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    var i = ca.length;
    while (i--) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  // Delete cookie
  self.delete = function(name) {
    self.set(name, '', -1);
  };

  return self;

})();
