'use strict';

var utils = {
  polyfills: require('./polyfills.js'),
  events: require('./events.js'),
  device: require('./device.js'),
  cookie: require('./cookie.js'),
  easings: {
    swiftOut: [0.55, 0, 0.1, 1],
    appleEase: [0.4, 0.01, 0.165, 0.99],
    appleEaseAlt: [0.28, 0.11, 0.32, 1]
  }
};

module.exports = utils;
