module.exports = (function () {

  var is = function () {

      var referencesList = window.referencesList;

      var currentPageId = $(".request").attr("data-id");

      if (referencesList) {

        var currentReferenceIndex;

        for (var i = 0; i < referencesList.length; i++) {
          if (referencesList[i].id === currentPageId) {
            currentReferenceIndex = i;
          }
        }

        var prevIndex = (currentReferenceIndex - 1);

        if (prevIndex > -1) {

          $(".historyLinks .-prev").addClass("-activated");
          $(".historyLinks .-prev").find("a").attr("href", referencesList[prevIndex].link);

        }

        var nextIndex = (currentReferenceIndex + 1);

        if (nextIndex < referencesList.length) {

          $(".historyLinks .-next").addClass("-activated");
          $(".historyLinks .-next").find("a").attr("href", referencesList[nextIndex].link);

        }

        $(".historyLinks").removeClass("-deactivated").addClass("-activated");

      }

  };

  var not = function () {

  };

  return {
    is: is,
    not: not
  }

}());
