module.exports = {
  is: function () {
    $('#tkd-form form').once('submit', function(e) {
      e.preventDefault();

      // Hide submit button
      $this = $(this);
      $this.find('input[type="submit"]').fadeOut(150);

      // Validate forms
      var formValid = true;

      var inputFields = $this.find('input[type="text"], input[type="tel"], input[type="email"], input[type="number"], textarea');
      $this.find('input, label, textarea').removeClass('error');

      inputFields.each(function() {
        var inputField = $(this);
        if (inputField.val() == '') {
          formValid = false;
          inputField.addClass('error');
        }
      });

      // Check radio fields
      var radioFields = ['prize_1', 'prize_2', 'prize_3'];

      for (i = 0; i < radioFields.length; i++) {
        if (!$('input[name="' + radioFields[i] + '"]:checked').length) {
          $('input[name="' + radioFields[i] + '"]').closest('label').addClass('error');
        }
      }

      // Make ajax request
      if (formValid) {
        $.ajax({
          type: "POST",
          url: "/other/ActiveCampaign.TKDForm.json",
          data: $this.serialize(),
          success: function(data) {
            if (data.result_code && data.result_code == 1) {
              $('.form-module').hide();
              $('.removeOnSubmit').hide();
              $('.form-success').show();
            } else {
              $('.form-module').hide();
              $('.form-fail').show();
            }
          },
          dataType: "json"
        });
      } else {
        $this.find('input[type="submit"]').fadeIn(150);
      }

    });
  },
  not: function () {
     $('#tkd-form form').off('submit');
  }
};
