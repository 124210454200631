module.exports = function(templateName, data, callback) {

  var render = function(html) {

    var doT = require("doT");
    var tempFn = doT.template(html);
    var resultText = tempFn(data);

    callback(resultText);

  };

  if (typeof window.templateCache === "undefined") {
    window.templateCache = {};
  }

  if (typeof window.templateListeners === "undefined") {
    window.templateListeners = {};
  }

  if (typeof window.templateListeners[templateName] !== "undefined") {
    window.templateListeners[templateName].push([data, callback]);
    return;
  }

  if (typeof window.templateCache[templateName] !== "undefined") {
    render(window.templateCache[templateName]);
    return;
  }

  window.templateListeners[templateName] = [];

  $.get("/webdav/files/resources/template/" + templateName + ".html", function(html) {

    window.templateCache[templateName] = html;

    render(html);

    var queue = [];

    window.templateListeners[templateName].forEach(function(templateListener) {
      queue.push(templateListener);
    });

    window.templateListeners[templateName] = undefined;

    queue.forEach(function(queueItem) {
      require("template")(templateName, queueItem[0], queueItem[1]);
    });

  });

};
