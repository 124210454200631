
/**
*   This module takes care of setting the currentLocale as a cookie for 404-pages to know where to go
*/

var cookieUtil = require('utils/cookie.js');


var currentLocale = {

    init: function() {
        this.cacheDom();
        this.bindEvents();
    },

    cacheDom: function() {
        this.$languageFlag = $('.languageFlag');
    },

    bindEvents: function() {
        this.$languageFlag.on('click', this.setCurrentLocale.bind(this));
    },

    setCurrentLocale: function(e) {
        var $target = $(e.target);
        var locale = $target.data('currentlocale');

        // set cookie languagePreference
        if (cookieUtil.get("cookie")) {
            cookieUtil.set("languagePreference", locale, 60 * 3600 * 1800, "/");
        }

    }


};

module.exports = currentLocale.init();
