module.exports = {
    is: function () {

        // ghost, will never be ran

    },
    not: function () {

        // get all tabBar li's
        $tabBarNavigationItems = $(".tabBar .navigation ul li");

        // upon clicking a tabBar navigational item switch the shown
        // tab
        $tabBarNavigationItems.once("click", function (event) {

            var $this = $(this);

            // get the index of the li
            var itemIndex = $(this).index();

            // find the containing tabBar
            var tabBar = $(this).parents(".tabBar");

            // find all tabs
            var tabs = tabBar.find(".tabs");

            // get the tab that corresponds to the index
            // of the li
            var tab = tabs.find(".tab").get(itemIndex);

            // add classes
            tabs.find(".tab").removeClass("-active");
            $(tab).addClass("-active");

            // remove classes
            tabBar.find(".navigation ul li").removeClass("-active");
            $this.addClass("-active");

        });

    }
};