'use strict';

var events = require('../../utils/events');

var registerProduct = function() {

  var $registerForm;

  var is = function() {

    $registerForm = $('#registerProduct');
    var locale = $('.request').data('locale').split('_')[0];

    var urlPieces = $registerForm.data('url').split('/');
    urlPieces.pop();
    var url = urlPieces.join('/') + '/';

    // Update html function
    var updateFormHTML = function(string) {
      var $form = $(string).filter('#page-wrapper').find('form');

      $form.attr('action', url + $form.attr('action'))
        .find('img').toArray().forEach(function(item) {
          var urlPieces = item.src.split('/');
          item.src = url + 'img/' + urlPieces[urlPieces.length - 1];
        });

      $registerForm.html($form);

      return $form;
    };

    var submitForm = function(event) {
      event.preventDefault();

      var $this = $(this);
      var url = $this.attr('action');
      var method = $this.attr('method');
      var data = {};
      var inputs = $this.find('[name]').toArray();

      // Loop though inputs
      inputs.forEach(function(item) {

        // If inputs are an array
        if (item.name.indexOf('[]') !== -1) {

          item.name = item.name.replace('[]', '');

          if (!Array.isArray(data[item.name])) {
            data[item.name] = [];
          }

        }

        if (item.type === 'checkbox' || item.type === 'radio') {

          if (item.checked) {

            if (Array.isArray(data[item.name])) {
              data[item.name].push(item.value);
            } else {
              data[item.name] = item.value;
            }

          }

        } else {
          data[item.name] = item.value;
        }

      });

      // Submit form
      $.ajax({
        url: url,
        method: method,
        data: data,
        beforeSend: function() {
          $this.append('<div id="loader"></div>');
        }
      })
      .done(function(response) {

        // Remove old el
        $this.remove();

        // Get new el
        var $form = updateFormHTML(response);
        $form.on('submit', submitForm);

        if ($('#message').length) {
          events.publish('registerProduct/submit', data);
          $('form')[0].reset();
        }

      });

    };

    // Get markup
    $.ajax({
      url: url + '?lang=' + locale
    })
    .done(function(response) {
      var $form = updateFormHTML(response);
      $form.on('submit', submitForm);
    });

  };

  var not = function() {

  };

  return {
    is: is,
    not: not
  };
};

module.exports = registerProduct();
