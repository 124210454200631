var modal = function (excavator, cookieUtil) {

    var generateDOM = function () {

        var options = $("<div/>", {
            'class': "options"
        });

        var brandContainer = $("<h3/>", {
            'html': '<span rv-text="translation.configurator.options.brand"></span> '
        });

        var brand = $("<span/>", {
            'rv-text': 'excavator.brand'
        });

        brandContainer.append(brand);
        options.append(brandContainer);

        var modelContainer = $("<h3/>", {
            'html': '<span rv-text="translation.configurator.options.model"></span> '
        });

        var model = $("<span/>", {
            'rv-text': 'excavator.model'
        });

        modelContainer.append(model);
        options.append(modelContainer);

        var weightContainer = $("<h3/>", {
            'html': '<span rv-text="translation.configurator.options.weight"></span> '
        });

        var weight = $("<span/>", {
            'rv-text': 'excavator.weight'
        });

        weightContainer.append(weight);
        options.append(weightContainer);

        var currentConfiguration = $("<div/>", {
            'class': 'currentConfiguration'
        });

        currentConfiguration.append(options);

        var logotype = $("<div/>", {
            'class': 'logotype'
        });

        var logotypeImg = $("<img/>", {
            'rv-image': "excavator.logo"
        });

        logotype.append(logotypeImg);

        currentConfiguration.append(logotype);

        var divider = $("<div/>", {
            'class': 'divider'
        });

        var border = $("<div/>", {
            'class': 'border'
        });

        divider.append(border);

        currentConfiguration.append(divider);

        var buttons = $("<div/>", {
            'class': 'buttons'
        });

        var clearButton = $("<a/>", {
            'href': '#',
            'rv-text': 'translation.configurator.options.clearButton',
            'class': 'clearButton',
            'click': function (event) {

                event.preventDefault();
                self();

                require("pageHandlers/ourProducts/clearExcavator")(excavator, cookieUtil);

            }
        });

        var productGuideButton = $("<a/>", {
            'href': '#',
            'rv-text': 'translation.configurator.options.productGuideButton',
            'class': 'productGuideButton',
            'click': function (event) {

                event.preventDefault();
                self();

                require("pageHandlers/ourProducts/configurator").modal(excavator, cookieUtil);

            }
        });

        buttons.append(clearButton);
        buttons.append(productGuideButton);

        currentConfiguration.append(buttons);

        rivets.bind(currentConfiguration, {
            excavator: excavator,
            translation: window.translation
        });

        return currentConfiguration;

    };

    var self = require("modal")({
        title: "translation.configurator.options.title",
        custom: generateDOM(),
        class: "-configuratorOptions"
    });

};

module.exports = {
    modal: modal
};
