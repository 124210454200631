module.exports = function() {

  var exception = $('ul.product__selectCategory');

  $(document).ready(function() {
    $(exception).on('click', function() {
      setTimeout(function() {
        require('pageHandlers/ourProducts/updateFilter')(true);
      }, 500);
    });
  });
};
