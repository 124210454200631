var updateFilterTimeout;
module.exports = function(silent) {

  var excavator = window.excavator;
  var productsList = window.productsList = [];

  var wasFiltered = false;

  var fetchCampaigns = function() {

    var callback = function(response) {

      if (typeof response.campaigns.length !== "number") {
        return;
      }

      response.campaigns.forEach(function(campaign) {

        campaign.connection.forEach(function(connection) {

          var category = $(".category[data-name='" + connection + "']");

          if (category.length === 0) {
            $(".campaigns[data-id='" + campaign.id + "-" + connection + "']").remove();
          }

          if (category.length !== 0) {
            if ($(".campaigns[data-id='" + campaign.id + "-" + connection + "']").length === 0) {

              category.before("<div class='campaigns' data-id='" + campaign.id + "-" + connection + "'>" + campaign.html + "</div>");

              $(".campaigns[data-id='" + campaign.id + "-" + connection + "'] .campaign").velocity(
                "slideDown",
                {duration: 250}
              );

            } else {

              $(".campaigns[data-id='" + campaign.id + "-" + connection + "']").insertBefore(category);

            }
          }

        });

      });

    };

    $.get("/other/api.campaigns.json?locale=" + $(".request").attr("data-locale")).done(callback);
  };

  var getProductMarkup = function(product) {

    var li = $("<li class='card -product product'><a href='" + product.url + "' class='card__link'></a></li>");

    li.attr("data-id", product.id);

    if (product.new == "Ja") {
        li.find("a").append("<div class='card__new' style='background-image: url(/webdav/files/resources/img/new-tags/" + $(".request").attr("data-locale") + ".png)'></div>");
    }

    var image = $("<figure class='card__figure' style='background-image: url(" + product.images[0] + ");'></figure>");
    li.find("a").append(image);

    li.find("a").append("<div class='card__body'></div>");

    li.find(".card__body").append("<h3>" + product.name + "</h3>");
    li.find(".card__body").append("<p>" + product.shortDescription + "</p>");

    li.find("a").append("<ul class='card__tags'></ul>");

    product.marking.forEach(function(marking) {
      li.find(".card__tags").html("<li class='tag card__tag'>" + marking + "</li>");
    });

    li.find("a").append("<span class='card__footer'></span>");

    li.find(".card__footer").append(
      "<span class='bookmark card__star'><i class='fa fa-star-o'></i></span>"
      + "<span class='card__more'><p rv-text='translation.moreInfo'></p> <i class='fa fa-chevron-right'></i></span>");

    rivets.bind(li.find(".card__footer"), {translation: window.translation});

    var indexOf = window.bookmarks.indexOf(product.id + "--" + $(".request").attr("data-locale"));

    if (indexOf !== -1) {
      li.find(".bookmark").addClass("-bookmarked");
    }

    return li;

  };

  var fetch = function(response) {

    var $this = $(this);
    var categoryKeys = Object.keys(response);

    categoryKeys.forEach(function(categoryKey) {

      response[categoryKey].forEach(function(product) {
        productsList.push({
          id: product.id,
          url: product.url
        });
      });

    });

    String.prototype.replaceArray = function (find, replace) {
        var replaceString = this;
        var regex;
        for (var i = 0; i < find.length; i++) {
            regex = new RegExp(find[i], "g");
            replaceString = replaceString.replace(regex, replace[i]);
        }
        return replaceString;
    };

    var find = ['�', '�', '�', '�', '�', '�', '�', '�', ' '];
    var replace = ['a', 'a', 'o', 'A', 'A', 'O', 'e', 'o', ''];

    console.log(categoryKeys);
    console.log(response);

    var $categoriesBar = $(".categoriesBar ul");
    var $productsContainer = $(".products__container");

    if (categoryKeys.length === 0) {
      $(".filterNotice--noResults").addClass("-activated");
    } else {
      $(".filterNotice--noResults").removeClass("-activated");
    }

    $categoriesBar.find("li").each(function() {
      if (categoryKeys.indexOf($(this).find("a").attr("data-name")) === -1) {
        $(this).remove();
      }
    });

    var productIds = [];
    var allSubCategories = [];

    categoryKeys.forEach(function (categoryKey) {

        var existingCategoryBarItem = $categoriesBar.find("li a[data-name='" + categoryKey + "']");

          if (existingCategoryBarItem.length === 0) {

            $categoriesBar.append(
              "<li><a href='#' data-name='"
              + categoryKey
              + "'><span>"
              + categoryKey
              + " <i class='count'> (" + response[categoryKey].length
              + ")</i></span></a></li>"
            );

          } else {
            existingCategoryBarItem.find(".count").text("(" + response[categoryKey].length + ")");
          }

          var existingCategory = $productsContainer.find(".category[data-name='" + categoryKey.replace(" ", "") + "']");

          if (existingCategory.length === 0) {

            $productsContainer.append("<div class='category' data-name='" + categoryKey.replace(" ", "") + "'></div>");

            $productsContainer
              .find(".category:last-child")
              .append("<h3 class='category__title'>"
                + categoryKey
                + " <i class='count'>("
                + response[categoryKey].length
                + ")</i>"
                + "</h3>");

            $productsContainer.find(".category:last-child").append("<ul class='products flexboxGrid'></ul>");

          } else {

            existingCategory.find(".category__title .count").text("(" + response[categoryKey].length + ")");

          }

          var ul = $productsContainer.find(".category[data-name='" + categoryKey.replace(" ", "") + "']").find(".products");

          var products = response[categoryKey];

          var subCategories = [];
          var subCategoryProducts = [];

          products.forEach(function (product) {

              if (product.category !== categoryKey && !wasFiltered) {
                if (subCategories.indexOf(product.category) === -1) {
                    subCategories.push(product.category);
                }
                subCategoryProducts.push(product);
                return;
              }

              productIds.push(product.id);

              if ($(".products .product[data-id='" + product.id + "']").length !== 0) {
                return;
              }

              ul.append(getProductMarkup(product));

          });

          subCategories.forEach(function (subCategory) {

            allSubCategories.push(subCategory);

            var subCategoryProductIds = [];
            var subCategoryImages;
            var subCategoryLowerCardFieldLinkText;

            for (var i = 0; i < subCategoryProducts.length; i++) {
              if (subCategoryProducts[i].category === subCategory) {
                subCategoryProductIds.push(subCategoryProducts[i].id);
                subCategoryImages = subCategoryProducts[i].images;
              }
            }

            var subCategoryImage = "";

            if (typeof subCategoryImages.length === 'number') {
              if (subCategoryImages.length > 0) {
                subCategoryImage = subCategoryImages[0];
              }
            }

            if (ul.find(".subCategoryCard[data-subCategory='" + subCategory + "']").length === 0) {

              if (typeof window.translation.seeAll === "undefined") {
                //window.translation.seeAll = "";
                  subCategoryLowerCardFieldLinkText = subCategory;
              } else {
                  subCategoryLowerCardFieldLinkText = window.translation.seeAll + " " + subCategory.toLowerCase();
              }

              ul.append("<li class='subCategoryCard card' data-subCategory='" + subCategory + "' data-subProducts='" + subCategoryProductIds.join(",") + "'><a href='#'>" +
                "<h3>" + subCategory + "</h3>" +
                "<span class='card__image'><figure class='image__figure' style='background-image: url(" + subCategoryImage + ")'></figure></span>" +
                "<span class='link'>" + subCategoryLowerCardFieldLinkText + "</span>" +
                "</a></li>");

            }

          });

          $(".ourProducts .subCategoryCard").once("click", function () {

            var subCategoryCard = $(this);
            var products = subCategoryCard.attr("data-subproducts");

            subCategoryCard.addClass("-loading");

            var fetchSubProducts = function (subResponse) {

              var subCategoryKeys = Object.keys(subResponse);

              var parentUl = subCategoryCard.parents("ul");
              var index = parseInt(subCategoryCard.index());

              subCategoryCard.remove();

              subCategoryKeys.forEach(function (subCategory) {

                  var productIndex = 0;

                  subResponse[subCategory].forEach(function (product) {

                    var productElem = getProductMarkup(product);
                    productElem.attr("data-id", product.id);
                    productElem.css("opacity", 0);

                    var currentIndex = parseInt(index);

                    setTimeout(function () {

                      if (parentUl.find("> li").length == 0) {
                        parentUl.append(productElem);
                      }

                      if (parentUl.find("> li:nth-child(" + (currentIndex + 1) + ")").length) {
                        parentUl.find("> li:nth-child(" + (currentIndex + 1) + ")").before(productElem);
                      } else {
                        parentUl.find("> li:nth-child(" + (currentIndex) + ")").after(productElem);
                      }

                      productElem.velocity({
                        'opacity': 1
                      }, { delay: 50, duration: 600 });

                    }, (productIndex * 150));

                    productIndex++;
                    index++;

                  });

              });

            };

            $.get(productSearcherBase + "pageId=" + products).done(fetchSubProducts);

          });

      });

      $productsContainer.find(".products > li").each(function () {

        if (allSubCategories.indexOf($(this).attr("data-subCategory")) !== -1) {
          return;
        }

        if (productIds.indexOf($(this).attr("data-id")) === -1) {

          if ($(this).parents("ul").find("li").length === 1) {
            $(this).parents(".category").remove();
          } else {
            $(this).remove();
          }

        }

      });

      $productsContainer.find(".category").each(function() {

        if ($(this).find("li").length === 0) {
          $(this).remove();
        }

      });

      $('.ourProducts .categoriesBar li').each(function() {

        var preferredIndex = categoryKeys.indexOf($(this).find("a").attr("data-name"));
        var actualIndex = $(this).index() - 1;

        if (preferredIndex !== actualIndex) {
          $(this).insertBefore($(this).parents(".ourProducts").find(".categoriesBar li:nth-child(" + (preferredIndex + 1) + ")"));
        }

      });

      $('.ourProducts .category').each(function() {

        var preferredIndex = categoryKeys.indexOf($(this).attr("data-name"));
        var actualIndex = $(this).index() - 1;

        if (preferredIndex !== actualIndex) {
          $(this).insertBefore($(this).parents(".products__container").find(".category:nth-child(" + (preferredIndex + 1) + ")"));
        }

      });

      $("body").removeClass("-loading");

      window.requestAnimationFrame(function () {
        $(".categoriesBar").trigger("data-update");
      });

      fetchCampaigns();

  };

  var productSearcherBase = "/other/api.product_searcher.json?";

  if (excavator.brand) {

    if (excavator.products) {
      var products = excavator.products.join(",");
      productSearcherBase += "productId=" + products + "&";
      wasFiltered = true;
    }

  } else {

    var slider = $(".filter__slider")[0];

    if (slider) {
      var values = slider.noUiSlider.get();
      window.sliderVal = values;
      productSearcherBase += "weight=" + values + "&";

      if (values !== "0.0") {
        wasFiltered = true;
      }
    }

    var category = $(".dropdown--filter .dropdown__container li.-activated");

    if (!category.hasClass("-placeholder")) {
      var categoryId = category.attr("data-id");

      window.categoryId = categoryId;

      if (categoryId) {
        productSearcherBase += "category=" + category.attr("data-id") + "&";
        wasFiltered = true;
      }
    }

    var search = $(".filter input").val();
    var searchPlaceholder = $('.filter input').attr('placeholder');

    if (search && search !== searchPlaceholder) {
      window.productSearch = search;
      productSearcherBase += "query=" + search + "&";
      wasFiltered = true;
    }

  }

  productSearcherBase = productSearcherBase + "locale=" + $(".request").attr("data-locale") + "&";

  updateFilterTimeout = setTimeout(function() {

    $.get(productSearcherBase).done(fetch);

    if (!silent) {
      $("body").addClass("-loading");
    }

  }, 16);
};