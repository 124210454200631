'use strict';

var cookie = require('../utils/cookie');
var events = require('../utils/events');

var logging = function() {

  var baseUrl = (function() {
    var url = window.location.href;
    var parts = url.split('/');
    return parts[0] + '//' + parts[2];
  })();

  // Product filter
  events.subscribe('productPage/filterWeight', function(data) {
    setTimeout(function() {
      ga('send', 'event', 'Products filter', 'filter', 'Filter by weight');

      data.email = cookie.get('email') || null;
      data.value = data.weight;
      data.event = 'Filter products by weight';

      if (!data.email) {
        return;
      }

      $.ajax({
        url: baseUrl + '/other/ActiveCampaign.ProductFilter.json',
        method: 'POST',
        data: data
      });

      $.ajax({
        url: baseUrl + '/other/ActiveCampaign.LogEvent.json',
        method: 'POST',
        data: data
      });

    }, 2000);
  });

  events.subscribe('productPage/filterCategory', function() {
    ga('send', 'event', 'Products filter', 'filter', 'Filter by category');
  });

  events.subscribe('productPage/filterSearch', function() {
    ga('send', 'event', 'Products filter', 'filter', 'Filter by search');
  });

  // Favorites
  events.subscribe('favorites/add', function() {
    ga('send', 'event', 'Favorites', 'add', 'Add favorite');
  });

  events.subscribe('favorites/remove', function() {
    ga('send', 'event', 'Favorites', 'remove', 'Remove favorite');
  });

  events.subscribe('favorites/share', function() {
    ga('send', 'event', 'Favorites', 'share', 'Share favorites');
  });

  events.subscribe('favorites/print', function() {
    ga('send', 'event', 'Favorites', 'print', 'Print favorites');
  });

  // Product guide
  events.subscribe('productPage/guideStart', function() {
    ga('send', 'event', 'Product guide', 'click', 'Start guide');
  });

  events.subscribe('productPage/guideFinish', function(data) {
    setTimeout(function() {
      ga('send', 'event', 'Product guide', 'click', 'Finish guide');

      data.email = cookie.get('email') || null;
      data.value = data.brand + ' ' + data.model;
      data.event = 'Product Guide';

      if (!data.email) {
        return;
      }

      $.ajax({
        url: baseUrl + '/other/ActiveCampaign.ProductGuide.json',
        method: 'POST',
        data: data
      })
      .done(function(res) {
        console.log(res);
      });

      $.ajax({
        url: baseUrl + '/other/ActiveCampaign.LogEvent.json',
        method: 'POST',
        data: data
      });

    }, 2000);
  });

  events.subscribe('registerProduct/submit', function(data) {
    var nameParts = data.first_last_name.split(' ');
    var lastName = nameParts.pop();
    var firstName = nameParts.join(' ');

    data.first_name = firstName;
    data.last_name = lastName;
    data.email = data.email_address;
    data.phone = data.mobile_number;

    console.log(data);

    $.ajax({
      url: baseUrl + '/other/ActiveCampaign.ProductRegister.json',
      method: 'POST',
      data: data
    })
    .done(function(res) {
      console.log(res);
    });

  });

};

module.exports = logging();
