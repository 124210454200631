var modal = function (excavator, cookieUtil, updateFilter) {

    var events = require('../../../utils/events');

    var base = "/other/configurator/configurator.json";
    var brandId;

    var alphabetClick = function () {

        var val = $(this).text();

        var isAll = false;

        if ($(this).hasClass("-all")) {
            isAll = true;
        }

        $(this).parent("ul").find("li").removeClass("-active");

        $(this).addClass("-active");

        var brands = $(".-configurator .configurator .excavatorBrand");

        $.each(brands, function () {

            if (isAll) {
                $(this).show();
                return;
            }

            if ($(this).attr("data-name").substring(0, 1) === val) {

                $(this).show();

            } else {

                $(this).hide();

            }

        });

    };

    var backButtonClick = function (event) {

        event.preventDefault();
        event.stopPropagation();

        var elements = generateDOM();

        var $content = $(".modalDialog__content");
        $content.empty();

        $content.append(elements);

    };

    var excavatorBrandClick = function (event) {

        var $this = $(this);
        brandId = $this.attr("data-id");

        var locale = $(".request").attr("data-locale");

        var $step1 = $(".-configurator .configurator .step1");

        $step1.append(require("loader"));

        var createStep2 = function (data) {

            var $step2 = $("<div/>", {
                'class': 'step2'
            });

            var header = $("<div/>", {
                'class': 'step2__header'
            });

            var headerUL = $("<ul/>");
            header.append(headerUL);

            var headerLogotype = $("<li/>");
            headerUL.append(headerLogotype);

            var headerLogotypeIMG = $("<img/>", {
                'src': data.Logo
            });
            headerLogotype.append(headerLogotypeIMG);

            var headerLogotypeBack = $("<a/>", {
                'href': '#',
                'rv-text': 'translation.configurator.change',
                'class': 'back-button',
                'click': backButtonClick
            });

            rivets.bind(headerLogotypeBack, { translation: window.translation });

            headerLogotype.append(headerLogotypeBack);

            for (var image = 1; image < 4; image++) {

                var imageURL = data["Image" + image];

                if (imageURL) {

                    var imageContainer = $("<li/>", {
                        'class': '-image',
                        'style': 'background-image: URL("'+ imageURL +'")'
                    });

                    headerUL.append(imageContainer);

                }

            }

            $step2.append(header);

            var models = $("<div/>", {
                'class': 'step2__models'
            });

            var modelsTitle = $("<h4/>", {
                'rv-text': 'translation.configurator.chooseModel'
            });

            rivets.bind(modelsTitle, { translation: window.translation });

            models.append(modelsTitle);

            var modelsContainer = $("<ul/>");

            var getCompatibleProducts = function (response) {

                var payload = response.payload[0];

                excavator.brandId = payload.BrandId;
                excavator.brand = payload.BrandName;

                var model = payload.Model[0];

                excavator.modelId = model.ModelId;
                excavator.weight = model.ModelMachineWeight + " ton";
                excavator.weightFloat = parseFloat(model.ModelMachineWeight.replace(",", "."));
                excavator.model = model.ModelName;
                excavator.logo = data.Logo;
                excavator.products = [];

                model.Products.forEach(function (product) {

                    excavator.products.push(product.id);

                });

                cookieUtil.set('excavator', JSON.stringify(excavator));

                var updateFilter = require("pageHandlers/ourProducts/updateFilter");

                if ($(".request").attr("data-template") === "ourProducts") {
                    updateFilter();
                    events.publish('productPage/guideFinish', {
                      brand: excavator.brand,
                      model: excavator.model,
                      weight: String(excavator.weightFloat)
                    });
                }

                self();

            };

            var modelClick = function (event) {

                $step2.append(require("loader"));

                var $model = $(this);

                $.get(base + "?step=3&locale=" +
                    locale +
                    "&brand=" +
                    data.BrandId +
                    "&model=" +
                    $model.attr("data-id")).done(getCompatibleProducts);

            };

            data.Model.forEach(function (modelItem) {

                var model = $("<li/>", {
                    'html': "<a href='#'><span>" + modelItem.ModelName + "</span></a>",
                    'data-id': modelItem.ModelId,
                    'data-name': modelItem.ModelName,
                    'click': modelClick
                });

                modelsContainer.append(model);

            });

            models.append(modelsContainer);

            $step2.append(models);

            $(".-configurator .configurator").append($step2);

            $(".-configurator .progress li").removeClass("-active");
            $(".-configurator .progress li:last-child").addClass("-active");

        };

        $.get(base + "?step=2&brand=" + brandId + "&locale=" + locale)
        .done(function (response) {

            $step1.find(".svg-outer").remove();
            $step1.hide();

            createStep2(response.payload[0]);

        });

    };

    var getExcavators = function (step) {

        step.append(require("loader"));

        var excavators = $("<ul/>", {
            'class': 'excavators'
        });

        var locale = $(".request").attr("data-locale");

        $.get(base + "?step=1&locale=" + locale)
        .done(function (response) {

            step.find(".outer-svg").remove();

            var payload = response.payload;
            var excavatorItems = [];
            var alphabet = [];

            payload.forEach(function (excavatorItem) {

                var excavatorChildren = [];

                var excavator = $("<li/>", {
                    'class': 'excavatorBrand',
                    'data-id': excavatorItem.BrandId,
                    'data-name': excavatorItem.BrandName,
                    'data-alphabet': excavatorItem.BrandName.substring(0, 1),
                    'click': excavatorBrandClick
                });

                var link = $("<a/>", {
                    'href': '#'
                });

                excavator.append(link);

                var image = $("<img/>", {
                    'src': excavatorItem.Logo
                });

                excavatorChildren.push(image);

                var divider = $("<div/>", {
                    'class': 'divider'
                });

                excavatorChildren.push(divider);

                var brandName = $("<p/>", {
                    'text': excavatorItem.BrandName
                });

                excavatorChildren.push(brandName);

                link.append(excavatorChildren);

                excavatorItems.push(excavator);

                alphabet.push(excavatorItem.BrandName.substring(0, 1));

            });

            alphabet.sort();

            var uniqueAlphabet = [];

            var allText = window.translation.configurator.all;
            uniqueAlphabet.push(allText);

            $.each(alphabet, function(i, el){
                if($.inArray(el, uniqueAlphabet) === -1) uniqueAlphabet.push(el);
            });

            var alphabetContainer = $("<ul/>", {
                'class': 'alphabet'
            });

            uniqueAlphabet.forEach(function (alphabetItem) {

                var className = alphabetItem;

                if (alphabetItem == allText) {
                    className = "all -active";
                }

                var character = $("<li/>", {
                    'text': alphabetItem,
                    'class': "-" + className,
                    'click': alphabetClick
                });

                alphabetContainer.append(character);

            });

            step.find(".search").append(alphabetContainer);

            var excavatorItemsHolder = $("<ul/>");

            excavatorItemsHolder.append(excavatorItems);
            step.append(excavatorItemsHolder);

        });

    };

    var searchPanelInputButtonClick = function (event) {

        var val = $(this).parent("div").find("input").val();

        var brands = $(".-configurator .configurator .excavatorBrand");

        $.each(brands, function () {

            if ($(this).attr("data-name").toLowerCase().indexOf(val.toLowerCase()) === -1) {

                $(this).hide();

            } else {

                $(this).show();

            }

        });

    };

    var searchPanelInputKeyUp = function (event) {

        if (event.keyCode == 13) {

            $(this).parent("div").find("a").click();

        }

    };

    var generateDOM = function () {

        var configurator = $("<div/>", {
            'class': 'configurator'
        });

        var step1 = $("<div/>", {
            'class': 'step1'
        });

        var searchPanel = $("<div/>", {
            'class': 'search'
        });

        var searchPanelTitle = $("<h4/>", {
            'rv-text': 'translation.configurator.chooseBrand'
        });

        rivets.bind(searchPanelTitle, { translation: window.translation });

        searchPanel.append(searchPanelTitle);

        var searchPanelInputContainer = $("<div/>", {
            'class': "input"
        });

        var searchPanelInput = $("<input/>", {
            'rv-placeholder': 'translation.configurator.searchBrand',
            'keyup': searchPanelInputKeyUp
        });

        rivets.bind(searchPanelInput, { translation: window.translation });

        var searchPanelInputButton = $("<a/>", {
            'href': "#",
            'html': '<i class="fa fa-search"></i>',
            'click': searchPanelInputButtonClick
        });

        searchPanelInputContainer.append(searchPanelInput);
        searchPanelInputContainer.append(searchPanelInputButton);

        searchPanel.append(searchPanelInputContainer);

        step1.append(searchPanel);

        getExcavators(step1);

        configurator.append(step1);

        var progress = $("<div/>", {
            'class': 'progress'
        });

        var progressUl =  $("<ul/>");
        progress.append(progressUl);

        var step1Li = $("<li/>", {
            'rv-html': 'translation.configurator.step1',
            'class': '-active step1-indicator'
        });

        rivets.bind(step1Li, { translation: window.translation });

        progressUl.append(step1Li);

        var step2Li = $("<li/>", {
            'rv-html': 'translation.configurator.step2',
            'class': 'step2-indicator'
        });

        rivets.bind(step2Li, { translation: window.translation });

        progressUl.append(step2Li);

        return [configurator, progress]

    };

    var self = require("modal")({
        title: "translation.configurator.title",
        custom: generateDOM(),
        class: '-configurator'
    });

};

module.exports = {
    modal: modal
};

