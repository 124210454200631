//
//  Module: searchInvoker
//
//  Exports: {function}()
//
//  2015 - Sam Pettersson
//
module.exports = function () {

    var eventHandlers = {};

    eventHandlers.click__closeSearchInvoker = function (event) {

        $target = $(event.target);

        if ($target.parents(".search").length) {
            return;
        }

        if ($target.parents(".search-foldout").length) {
            return;
        }

        $(".searchInvoker.-activated").click();

    };

    eventHandlers.click__searchClose = function (event) {

        event.preventDefault();

        $(".searchInvoker.-activated").click();

    };

    // Handles opening and closing of the menu
    eventHandlers.click__searchInvoker = function (event) {

        event.preventDefault();

        var $this = $(this);
        var $search = $(".header .search");
        var $body = $("body");
        var $html = $("html");
        var activatedClass = "-activated";
        var searchActivatedClass = "-searchActivated";

        if (!$search.hasClass(activatedClass)) {
            $search.addClass(activatedClass);
            $this.addClass(activatedClass);
            $body.addClass(searchActivatedClass);
            //$html.addClass(searchActivatedClass);

            // Don't attach eventHandler directly, wait for a repaint
            window.requestAnimationFrame(function () {
                $("body").on("click", eventHandlers.click__closeSearchInvoker);
                $search.find("input").focus();
            });
        } else {
            $search.removeClass(activatedClass);
            $this.removeClass(activatedClass);
            $body.removeClass(searchActivatedClass);
            //$html.removeClass(searchActivatedClass);

            $search.find("input").val("");
            $search.trigger("keyup");

            $("body").off("click", eventHandlers.click__closeSearchInvoker);
        }

    };

    $(".searchInvoker").on("click", eventHandlers.click__searchInvoker);
    $(".searchClose").on("click", eventHandlers.click__searchClose);

};
