module.exports = {
  is: function() {
      /*
    // show excavator
    // $(".header .excavator").addClass("-activated");

    var $dropdownFeature = $(".dropdown--feature");

    // this function shuffles all the features on the startpage
    function Shuffle(o) {
      for(var j, x, i = o.length; i; j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
      return o;
    };

    // when dropdown changes, reload data from API
    $dropdownFeature.once("dropdown-change", function() {

      var source = $(".features").attr("data-source");
      var selectedRegion = $(this).find(".dropdown__container li.-activated").attr("data-region");

        // change the region cookie
      var consent = require("../../utils/cookie").get("cookie");
      if (consent) {
          require("../../utils/cookie").set("region", selectedRegion, 1000);
      }

      $.get(source).done(function(response) {

        // TODO error handling
        var features = response.payload.features;
        var $features__container = $(".features .features__container");
        $features__container.empty();

        // add features to container

        //Shuffle(features).
        features.forEach(function(feature) {
          $features__container.append("<li class='" + feature.CSSClassName + "'>" + feature.html + "</li>")
        });

        // add classes
        if (!features.length) {
          $(".features__container--empty").addClass("-activated");
        } else {
          $(".features__container--empty").removeClass("-activated");
        }

      });

    });
      */
    var updateFilter = require("pageHandlers/ourProducts/updateFilter");
    var cookieUtil = require("../../utils/cookie");
    var events = require("../../utils/events");

    $(".product-guide .btn").once("click", function () {
        require("pageHandlers/ourProducts/configurator").modal(excavator, cookieUtil, updateFilter);
        events.publish('productPage/guideStart');
    });

    // trigger from start on page load
    $dropdownFeature.trigger("dropdown-change");

    var userlocale = cookieUtil.get("userlocale");
    var fbPage = "engcon";
    if (userlocale) {
        if (userlocale == "sv_SE")
            fbPage = "engconsweden";
        else if (userlocale == "no_NO")
            fbPage = "engconnorge";
        else if (userlocale == "da_DK")
            fbPage = "engcondenmark";
        else if (userlocale == "fi_FI")
            fbPage = "engconfi";
        else if (userlocale == "en_GB")
            fbPage = "engconuk";
        else if (userlocale == "de_DE")
            fbPage = "engcongermany";
        else if (userlocale == "fr_FR")
            fbPage = "engconfrance";
        else if (userlocale == "nl_NL")
            fbPage = "engconnetherlands";
    }
    if (userlocale == "no_NO")
        userlocale = "nn_NO";

    //$(document).ready(function () {
        $.ajaxSetup({ cache: true });
        $.getScript('//connect.facebook.net/' + userlocale + '/sdk.js', function () {
            FB.init({
                appId: fbPage,
                version: 'v2.11'
            });
            $('#loginbutton,#feedbutton').removeAttr('disabled');
            var page_like = function (url, html_element) {
                console.log("page_like");
                window.setTimeout(function () {

                    window.requestAnimationFrame(function () {
                        $(".facebook-banner").removeClass("-activated");
                    });

                }, 800);
                var consent = require("../../utils/cookie").get("cookie");
                if (consent) {
                    cookieUtil.set("facebook", "true", 1000);
                }
            }
            FB.Event.subscribe('edge.create', page_like);
        });
      //});

    if (!($(".languageSwitcher").hasClass("-activated")) && userlocale) {

        if ($(window).width() > 520) {
            $(".fb-page").attr("data-width", "250");
            $(".fb-page").attr("data-show-facepile", "true");
            $(".fb-page").attr("data-small-header", "false");
        } else {
            $(".fb-page").attr("data-width", "250");
            $(".fb-page").attr("data-show-facepile", "false");
            $(".fb-page").attr("data-small-header", "true");
        }

        if (!cookieUtil.get("facebook")) {

            var $cookieBanner = $(".facebook-banner");

            window.setTimeout(function () {

                window.requestAnimationFrame(function () {
                    $(".facebook-banner").addClass("-activated");
                });

            }, 800);

            $(".facebook-banner > #close").on("click", function () {

                $(".facebook-banner").removeClass("-activated");

                var consent = require("../../utils/cookie").get("cookie");
                if (consent) {
                    cookieUtil.set("facebook", "true", 1000);
                }

                $("body").off("click");
            });

        }

    }

    var owl = $('.owl-carousel');

    owl.owlCarousel({
        items: 1,
        loop: true,
        margin: 0,
        autoplay: true,
        //autoPlayTimeout: 500,
        autoplayHoverPause: true,
        stagePadding: 0,
        lazyLoad: true,
        //rewind: true,
        //rewindNav: true,
        //rewindSpeed: 1000,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        nav: true,
        dots: true,
    });
    
    var $linkedSlide = $(".linked");

    $linkedSlide.css('cursor', 'pointer');

    $linkedSlide.on('click', function () {
        if ($(this).attr("data-uri")) {
            window.location.href = $(this).attr("data-uri");
        }
    });

    $(".item").each(function(){
        if ($(this).attr("data-uri") && $(this).attr("data-item") === "href") {
            if ($(this).find("a").attr("href")) {
                $(this).find("a").attr("href", $(this).attr("data-uri"));
            }
        }
    });

  },
  not: function() {
    // hide excavator
    //$(".header .excavator").removeClass("-activated");

    // detach eventHandler
    //$(".dropdown--feature").off("dropdown-change");
  }
};
