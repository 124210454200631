//
//  Module: dropdown - handles activation of dropdowns
//
//  Exports: {function}()
//
//  2016 - Sam Pettersson
//

module.exports = function() {

  var eventHandlers = {};

  eventHandlers.click__body = function(event) {

    var $target = $(event.target);

    if ($target.parents(".dropdown.-activated").length) {
      return;
    }

    $("body").off("click", eventHandlers.click__body);

    window.requestAnimationFrame(function() {
      $(".dropdown.-activated").click();
    });

  };

  eventHandlers.click__dropdown = function(event) {

    var $this = $(this);

    var expander = $(".pageContainer");

    if ($this.attr("data-expander")) {
      expander = $($this.attr("data-expander"));
    }

    if ($this.hasClass('-disabled')) {
      return;
    }

    if (!$this.find(".foldout").hasClass("-activated")) {

      $this.find(".foldout").addClass("-activated");
      $this.addClass("-activated");
      $this.find(".foldout").empty();

      $(this).find(".dropdown__container li").each(function(index) {

        if (!$(this).hasClass("-placeholder")) {

          var elem = $(this).clone();

          elem.removeClass(".-activated");
          elem.attr("data-index", index);

          if (elem.attr("data-link")) {
            elem.find("span").wrap("<a href='" + elem.attr("data-link") + "'></a>");
          }

          $this.find(".foldout").append(elem);

        }

      });

      var foldoutTop = $this.find(".foldout").get(0).getBoundingClientRect().top + 200;
      var windowHeight = document.body.offsetHeight;

      if (foldoutTop > windowHeight || $this.attr("data-expander-always") === "true") {
        expander.addClass("-expand");
      }

      $("body").on("click", eventHandlers.click__body);

    } else {

      $this.find(".foldout").removeClass("-activated");
      $this.removeClass("-activated");

      var $target = $(event.target);

      if ($target.is("span")) {
        $target = $target.parent("li");
      }

      if ($target.is("li")) {
        if ($target.find("a")) {
          $target.find("a").trigger("click");
        }
      }

      if ($target.parents(".foldout").length) {
        var index = $target.attr("data-index");
        var elem = $($this.find(".dropdown__container li").get(index));

        $this.find(".dropdown__container li").removeClass("-activated");
        elem.addClass("-activated");
        $this.trigger("dropdown-change", elem);
        if ($("#country").length) {
          $("#country").val(elem.attr("data-region"));
        }
      }

      expander.removeClass("-expand");

      $("body").off("click", eventHandlers.click__body);

    }

  };

  eventHandlers.change__dropdown = function(event) {
    console.log(event);
  };

  $("html").on("click", ".dropdown", eventHandlers.click__dropdown);

};
