module.exports = {
    is: function () {

        if ($(".subPage .heroImage.-videoPointer").length && $(".subPage .back").length) {

            $("body").addClass("subPage--image");

        }

        $(".subPage .heroImage.-videoPointer").once("click", function (event) {

            $("body").velocity("scroll", {
                offset: 0,
                duration: 50
            });

            var html = $(".subPage .video").html();
            html = html.replace("shouldAutoplay", "autoplay");

            require("modal")({
                title: "",
                custom: html,
                class: "-video"
            });

        });

        if ($(".request").attr("data-template") == "subPageVertical") {

            $("body").addClass("subPage--vertical");

        }

    },
    not: function () {

        $("body").removeClass("subPage--image");
        $("body").removeClass("subPage--vertical");

    }
};