module.exports = {
  is: function() {

    var cookieUtil = require("../../utils/cookie");
    var events = require("../../utils/events");

    require("pageHandlers/ourProducts/slider")();

    var excavator = window.excavator;

    var updateFilter = require("pageHandlers/ourProducts/updateFilter");

    // if excavator exists
    if (excavator.brand) {
      updateFilter();
    } else {
      // run a silent fetch
      updateFilter();
    }

    $(".filter .filterSearch a").once("click", function() {
      updateFilter();
      events.publish('productPage/filterSearch');
    });

    if (window.productSearch) {
      $(".filter .filterSearch input").val(window.productSearch);
      updateFilter();
    }

    $(".filter .filterSearch input").once("keyup", function(event) {

      if (event.keyCode === 13) {
        updateFilter();
        events.publish('productPage/filterSearch');
      }

    });

    $(".dropdown--filter").once("dropdown-change", function() {
      //updateFilter();
      //events.publish('productPage/filterCategory');
    });

    if (window.categoryId) {
      $(".dropdown--filter li").removeClass("-activated");
      $(".dropdown--filter li[data-id='" + window.categoryId + "']").first().addClass("-activated");
      updateFilter();
    }

    $(".filter--excavator a").once("click", function() {

      require("pageHandlers/ourProducts/clearExcavator")(excavator, cookieUtil);

    });

    $(".product-guide .btn").once("click", function() {

      require("pageHandlers/ourProducts/configurator").modal(excavator, cookieUtil, updateFilter);
      events.publish('productPage/guideStart');

    });

    $(".categoriesBar").on("click", "a", function(event) {

      event.preventDefault();

      // prevent other event listeners to do their thing
      event.stopPropagation();

      dataName = $(this).attr("data-name");

      // replace segments
      var find = ['å', 'ä', 'ö', 'Å', 'Ä', 'Ö', 'é', 'ø', ' '];
      var replace = ['a', 'a', 'o', 'A', 'A', 'O', 'e', 'o', ''];

      // replace
      String.prototype.replaceArray = function(find, replace) {
        var replaceString = this;
        var regex;
        for (var i = 0; i < find.length; i++) {
          regex = new RegExp(find[i], "g");
          replaceString = replaceString.replace(regex, replace[i]);
        }
        return replaceString;
      };


      var offsetTop = $(".category[data-name='" + dataName.replaceArray(find, replace) + "']").offset().top;

      // set duration according to the offset
      var duration = (offsetTop / 500) * 100;

      $(".pageContainer").velocity("scroll", {duration: duration, offset: offsetTop});

    });

    rivets.binders.showfilter = function(el, value) {

      if (typeof value === "undefined") {
        $(el).addClass("-activated");
        $(el).removeClass("-deactivated");
      } else {
        $(el).removeClass("-activated");
        $(el).addClass("-deactivated");
      }

    };

    rivets.binders.hidefilter = function(el, value) {

      if (typeof value === "undefined") {
        $(el).removeClass("-activated");
        $(el).addClass("-deactivated");
      } else {
        $(el).addClass("-activated");
        $(el).removeClass("-deactivated");
      }

    };

    rivets.bind($(".products__hero"), {excavator: excavator});
    rivets.bind($(".filterNotice"), {excavator: excavator});

    // always show myFavorites on the ourProducts page
    $(".myFavorites__container").addClass("-activated");

    // if (window.location.href.indexOf("categoryNav") !== -1) {
    //   var splitted = window.location.href.split("?categoryNav=");

    //   var scrollTo = function() {
    //     $(".categoriesBar a[data-name='" + splitted[1] + "']").click();
    //   };

    //   if (excavator.brand) {
    //     $(".categoriesBar").on("data-update", function() {
    //       scrollTo();
    //     });
    //   } else {
    //     scrollTo();
    //   }
    // }


    function scrollToSection(category) {

      // replace
      String.prototype.replaceArray = function(find, replace) {
        var replaceString = this;
        var regex;
        for (var i = 0; i < find.length; i++) {
          regex = new RegExp(find[i], "g");
          replaceString = replaceString.replace(regex, replace[i]);
        }
        return replaceString;
      };


       var find = ['å', 'ä', 'ö', 'Å', 'Ä', 'Ö', 'é', 'ø', ' '];
       var replace = ['a', 'a', 'o', 'A', 'A', 'O', 'e', 'o', ''];

      var offsetTop = $(".category[data-name='" + category.replaceArray(find, replace) + "']").offset().top;

      // set duration according to the offset
      var duration = (offsetTop / 500) * 100;

      $(".pageContainer").velocity("scroll", {duration: duration, offset: offsetTop});
  }


  // click
  //var excavator = window.excavator;

  if (window.location.href.indexOf("categoryNav") !== -1) {

      var splitted = window.location.href.split("?categoryNav=");
      var category = splitted[1];

      if (excavator.brand) {
        $(".categoriesBar").on("data-update", function() {

          splitted = window.location.href.split("?categoryNav=");
          category = splitted[1];
          scrollToSection(category);

        });
      } else {
        scrollToSection(category);
      }
    }


    // scroll

    $(".categoriesBar").on("click", "a", function(event) {

      event.preventDefault();

      // prevent other event listeners to do their thing
      event.stopPropagation();

      var dataName = $(this).attr("data-name");

      scrollToSection(dataName);

    });

  },
  not: function() {

  }
};
