module.exports = function (excavator, cookieUtil) {

    var clear = function () {

        cookieUtil.set("excavator", "");

        var keys = Object.keys(excavator);

        keys.forEach(function (key) {
            excavator[key] = undefined;
        });

        if ($(".request").attr("data-template") === "productTemplate") {
            $(".historyLinks").removeClass("-activated");
        }

        if ($(".request").attr("data-template") === "ourProducts") {
            require("pageHandlers/ourProducts/updateFilter")();
        }

    };

    require('modal')({
        title: "translation.configurator.clear.title",
        message: "translation.configurator.clear.message",
        button: {
            URL: "#",
            title: "translation.configurator.clear.OKButton",
            click: function (args, event, close) {

                event.preventDefault();

                clear();

                close();

            }
        },
        cancelButton: {
            URL: "#",
            title: "translation.configurator.clear.cancelButton",
            click: function (args, event, close) {

                event.preventDefault();
                close();

            }
        }
    });

};
