var removeURLParameter = function(url, parameter) {
  var urlparts = url.split('?');
  if (urlparts.length >= 2) {

    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);

    for (var i = pars.length; i-- > 0;) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");

  }

  return url;
};

module.exports = {
  is: function() {

    $('.search .js-tag-remove').once('click', function(e) {
      e.preventDefault();

      $('html').trigger('navigate', {
        url: removeURLParameter(location.pathname + window.location.search, 'tag')
      });

    });

    $(".search .navigationBar .navigationBar__item").once("click", function() {

      var name = $(this).attr("data-name");

      var offsetTop = $(".search h2[data-name='" + name + "']").offset().top;

      // set duration according to the offset
      var duration = (offsetTop / 500) * 100;

      $(".pageContainer").velocity("scroll", {
        duration: duration, offset: offsetTop - 15
      });

    });

  },
  not: function() {

  }
};
