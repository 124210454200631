module.exports = function (args) {

    var close = function (event) {

        if (event) {

            var $target = $(event.target);

            if ($target.parents(".modalDialog").length && !$target.parents(".close").length) {
                return;
            }

        }

        var scrollTop = $("html").css("margin-top").replace("px", "");

        $("html").removeClass("-noScroll");

        $("html").css("margin-top", 0);
        $('html, body').animate({
          scrollTop: Math.abs(scrollTop)
        }, 0);

        modalDialogHolder.addClass("-hide");
        modalDialogShader.addClass("-hide");

        setTimeout(function () {

            modalDialogHolder.remove();
            modalDialogShader.remove();

        }, 600);

    };

    var title = args.title;
    var message = args.message;
    var button = args.button;
    var cancelButton = args.cancelButton;

    var custom = args.custom;

    var className = args.class;

    var $body = $("body");

    var modalDialogShader = $("<div/>", {
        'class': 'modalDialogShader'
    });

    $body.append(modalDialogShader);

    var modalDialogHolder = $("<div/>", {
        'class': 'modalDialog'
    });

    modalDialogHolder.on("click", close);

    $body.append(modalDialogHolder);

    var modalDialogContainer = $("<div/>", {
        'class': 'modalDialog__container'
    });

    modalDialogHolder.append(modalDialogContainer);

    var modalDialogContent = $("<div/>", {
        'class': 'modalDialog__content'
    });

    var modalDialogHeader = $("<div/>", {
        'class': 'modalDialog__header'
    });

    var modalDialogHeaderTitle = $("<div/>", {
        'class': 'title',
        'html': '<h4>' + title + '</h4>'
    });

    if (title.indexOf("translation") !== -1) {
      modalDialogHeaderTitle.find("h4").attr("rv-text", title);
      rivets.bind(modalDialogHeaderTitle, { translation: window.translation });
    }

    modalDialogHeader.append(modalDialogHeaderTitle);

    var modalDialogHeaderClose = $("<div/>", {
        'class': 'close',
        'html': '<i class="fa fa-times"></i>'
    });

    modalDialogHeader.append(modalDialogHeaderClose);

    modalDialogContainer.append(modalDialogHeader);

    modalDialogContainer.append(modalDialogContent);

    if (message) {

        var modalDialogMessage = $("<div/>", {
            'class': 'message',
            'text': message
        });

        if (message.indexOf("translation") !== -1) {
          modalDialogMessage.attr("rv-text", message);
          rivets.bind(modalDialogMessage, { translation: window.translation });
        }

        modalDialogContent.append(modalDialogMessage);

    }

    if (button) {

        var modalDialogButtonHolder = $("<div/>", {
            'class': 'button__holder'
        });

        var buttonLink = $("<a/>", {
            'href': button.URL,
            'text': button.title,
            'class': 'btn -autoWidth'
        });

        if (button.title.indexOf("translation") !== -1) {
          buttonLink.attr("rv-text", button.title);
          rivets.bind(buttonLink, { translation: window.translation });
        }

        if (button.click) {

            buttonLink.on("click", function (event) {
                return button.click(args, event, close);
            });

        }

        modalDialogButtonHolder.append(buttonLink);

        if (cancelButton) {


            var cancelButtonLink = $("<a/>", {
                'href': cancelButton.URL,
                'text': cancelButton.title,
                'class': 'btn -autoWidth'
            });

            if (cancelButton.title.indexOf("translation") !== -1) {
              cancelButtonLink.attr("rv-text", cancelButton.title);
              rivets.bind(cancelButtonLink, { translation: window.translation });
            }

            if (cancelButton.click) {

                cancelButtonLink.on("click", function (event) {
                    return cancelButton.click(args, event, close);
                });

            }

            modalDialogButtonHolder.append(cancelButtonLink);

        }

        modalDialogContent.append(modalDialogButtonHolder);

    }

    if (custom) {

        modalDialogContent.append(custom);

        modalDialogContent.find(".close-icon").on("click", function () {
            close();
        });

    }

    if (className) {

        modalDialogHolder.addClass(className);

    }

    //console.log($("body").scrollTop());


    $("html").css("margin-top", -Math.abs($("body").scrollTop()));
    $("html").addClass("-noScroll");

    return close;

};
