module.exports = function (bookmarks) {

    var self;

    var generateDOM = function () {

        var shareContainer = $("<div/>", {
            'class': 'share__container'
        });

        var description = $("<p/>", {
            'rv-text': 'translation.shareModal.body'
        });
        shareContainer.append(description);

        var sender = $("<input/>", {
            'class': 'sender',
            'rv-placeholder': 'translation.shareModal.sender'
        });
        shareContainer.append(sender);

        var receiver = $("<input/>", {
            'class': 'receiver',
            'rv-placeholder': 'translation.shareModal.receiver'
        });
        shareContainer.append(receiver);

        var divider = $("<div/>", {
            'class': 'divider'
        });
        shareContainer.append(divider);

        var button = $("<a/>", {
            'href': '#',
            'class': 'sendButton',
            'rv-text': 'translation.shareModal.send',
            'click': function (event) {

                $.get("/other/api.favorites.json?toEmail=" + receiver.val() + "&from=" + sender.val() + "&id=" + bookmarks.join(","))
                    .done(function (response) {
                        self();
                        events.publish('favorites/share');
                    });

            }
        });
        shareContainer.append(button);

        rivets.bind(shareContainer, { translation: window.translation });

        return shareContainer;

    };

    $(".myFavorites .share .email").once("click", function (event) {

        event.preventDefault();

        self = require('modal')({
            "title": "translation.shareModal.title",
            "custom": generateDOM(),
            "class": '-favoritesShare'
        });

    });

};
