module.exports = {
    is: function () {
        $('#subscription-form form').once('submit', function (e) {
            e.preventDefault();

            // Hide submit button
            $this = $(this);
            $this.find('input[type="submit"]').fadeOut(150);

            // Validate forms
            var formValid = true;

            var inputFields = $this.find('input[type="text"], input[type="tel"], input[type="email"], input[type="hidden"], input[type="number"], input[type="checkbox"], textarea');
            $this.find('input, label, textarea').removeClass('error');

            inputFields.each(function () {
                var inputField = $(this);
                /*if (inputField.val() == '') {
                    formValid = false;
                    inputField.addClass('error');
                }*/

                if (inputField.is(':checkbox')) {
                    if (!inputField.is(':checked')) {
                        inputField.parent().addClass('error');
                        inputField.addClass('error');
                        formValid = false;
                    }
                } else {
                    if (inputField.val() === '' && inputField.attr("name") !== "userstate") {
                        inputField.addClass('error');
                        formValid = false;
                    } else {
                        if (inputField.attr("name") === "userstate" && inputField.val() === '' && $("#usercountry").val() === "US") {
                            inputField.addClass('error');
                            formValid = false;
                        }
                    }
                }
            });

            // Make ajax request
            if (formValid) {
                $.ajax({
                    type: $this.attr('method'),
                    url: $(this).attr('action'),
                    data: $this.serialize(),
                    success: function (data) {
                        if (data.result_code && data.result_code == 1) {
                            $('.form-module').hide();
                            $('.removeOnSubmit').hide();
                            $('.form-success').show();
                            setTimeout(function () {
                                $('.printarchive__subscribe').trigger('click');
                            }, 4500);
                        } else {
                            $('.form-module').hide();
                            $('.form-fail').show();
                        }
                    },
                    dataType: "json"
                });
            } else {
                $this.find('input[type="submit"]').fadeIn(150);
            }

            //$('#subscription-form').delay(4000).hide(300);
            //$("#subscribe").delay(4500).removeClass('-activated').delay(4500);
        });

        if ($(".documentsListingPage .dropdown--country").find(".dropdown__container li.-activated").data("value") == "US") {
            $(".documentsListingPage .dropdown--country").parent().removeClass("sv-column-12");
            $(".documentsListingPage .dropdown--country").parent().addClass("sv-column-6");
            $(".documentsListingPage .dropdown--state").parent().removeClass("-inactive")
        }

        $(".documentsListingPage .dropdown--country").on('dropdown-change', function () {
            $("#usercountry").val($(".documentsListingPage .dropdown--country > .dropdown__container > li.-activated").data("value"));
            if ($(".documentsListingPage .dropdown--country > .dropdown__container > li.-activated").data("value") != "US") {
                $("#userstate").val("");
                $(".documentsListingPage .dropdown--state > .dropdown__container > li").each(function () {
                    if ($(this).hasClass("-activated")) {
                        $(this).removeClass("-activated");
                    }
                });
                $(".documentsListingPage .dropdown--state > .dropdown__container > li:first").addClass("-activated");
                $(".documentsListingPage .dropdown--country").parent().removeClass("sv-column-6");
                $(".documentsListingPage .dropdown--country").parent().addClass("sv-column-12");
                $(".documentsListingPage .dropdown--state").parent().addClass("-inactive");
            } else {
                $(".documentsListingPage .dropdown--country").parent().removeClass("sv-column-12");
                $(".documentsListingPage .dropdown--country").parent().addClass("sv-column-6");
                $(".documentsListingPage .dropdown--state").parent().removeClass("-inactive");
            }
        });

        $(".documentsListingPage .dropdown--state").on('dropdown-change', function () {
            $("#userstate").val($(".documentsListingPage .dropdown--state > .dropdown__container > li.-activated").data("value"));
        });

        $(document).ready(function () {
            $(this).find(".dropdown--folder > .dropdown__container > li").each(function () {
                if ($(this).hasClass("-activated") && ($(this).attr("data-type") == "customermagazines")) {
                    $('.printarchive__subscribe').addClass('-activated');
                    $('.printarchive__subscription').addClass('-activated');
                }
            })
            $(".printarchive__subscribe").click(function () {
                if ($("#subscription-form").hasClass('-activated')) {
                    $("#subscribe").removeClass('-activated');
                    $("#subscription-form").hide(300);
                    $("#subscription-form").removeClass('-activated');
                } else {
                    $("#subscribe").addClass('-activated');
                    $("#subscription-form").show(300);
                    $("#subscription-form").addClass('-activated');
                }
            })
        })
    },
    not: function () {
        $(".printarchive__subscribe").off('click');
        $('#subscription-form').off('submit');
    }
};
