module.exports = function() {

  var events = require('../../../utils/events');

  var $sliderHeader = $(".slider__header");
  var slider = $(".filter__slider")[0];

  var start = $sliderHeader.attr("data-min");

  if (window.sliderVal) {
    start = parseFloat(window.sliderVal);
  }

  noUiSlider.create(slider, {
    start: start,
    connect: false,
    tooltips: true,
    step: 0.5,
    range: {
      min: parseFloat($sliderHeader.attr("data-min")),
      max: parseFloat($sliderHeader.attr("data-max"))
    },
    format: {
      to: function(value) {

        var val = Math.round(value * 10) / 10;

        if (val % 1 === 0) {
          val = val + ".0";
        }

        return val;

      },
      from: function(value) {
        return value;
      }
    }
  });

  var updateTimeout;

  slider.noUiSlider.on('update', function(values) {
    clearTimeout(updateTimeout);

    if (parseInt(values[0], 10) === 0) {
      $(".min").addClass("-hide");
    } else {
      $(".min").removeClass("-hide");
    }

    var val = String(values[0]);

    if (val.length === 4) {
      $(".min .val").text(val.replace(/^0+/, ''));
    } else {
      $(".min .val").text(val);
    }

    updateTimeout = setTimeout(function() {
      require('pageHandlers/ourProducts/updateFilter')(true);
    }, 500);
  });

  slider.noUiSlider.on('change', function(values) {
    events.publish('productPage/filterWeight', {
      weight: String(values[0])
    });
  });

};
